import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Customers from "../../pages/customers/Customers";
import Devices from "../../pages/devices/Devices";
import Sites from "../../pages/sites";
import ViewSite from "../../pages/viewSite/ViewSite";

// context
import { useLayoutState } from "../../context/LayoutContext";
import Users from "../../pages/users/Users";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/customers" component={Customers} />

              {/* Workaround to reload props on sites using if RELOAD in /app/sites*/}
              <Route path="/app/sitesTmp" render={(props) => {
                return <Redirect to={{ pathname: "/app/sites", state:  props.location.state  }}/>
              }}/>

              {/* Specific search path so it is possibly to load a new viewSite from viewSite*/}
              <Route path="/app/search" render={(props) => {
                return <Redirect to={{ pathname: "/app/viewSite", state:  props.location.state  }}/>
              }}/>

              <Route path="/app/sites" render={(props) => {
                if (props.location.state) {
                  if(props.location.state.RELOAD) {
                    return <Redirect to={{ pathname: "/app/sitesTmp", state: undefined }}/>
                  }
                  props.location.state["RELOAD"] = true;
                  return <Sites {...props.location.state}></Sites>
                }
                return <Sites/>
              }}/>

              <Route path="/app/viewSite"  render={(props) => {
                if(props.location.state && props.location.state.siteId) {
                  return <ViewSite {...props.location.state}></ViewSite>
                } 
                return <Redirect to="/app/sites" />
                
              }}/>
              <Route path="/app/openSiteIncident/:siteId/:incidentId"  render={(props) =>  {
                let siteId;
                let incidentId;
                if (props.match && props.match.params) {
                  siteId =  props.match.params.siteId;
                  incidentId = props.match.params.incidentId;
                }
                if (!siteId || !incidentId) {
                  return <Sites/>
                }
                return <ViewSite {...{siteId, incidentId, querySiteProps: true}}></ViewSite>
              }}/>    
              <Route path="/app/devices" component={Devices} />
              <Route path="/app/users" component={Users} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
