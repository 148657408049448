import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',    
    fontSize: "0.7rem",
    fontWeight: "bold",
  }
}));
