import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

// styles
import { Typography } from "@material-ui/core";

export default function UnlinkDeviceDialog(props) {

  const { title, subTitle, promptText, onClose, onUnlinkDevice, open, tags } = props;



  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
          <Typography>{subTitle}</Typography>
          {tags}
          <Typography>{promptText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Peruuta
        </Button>
        <Button 
          variant="contained" 
          onClick={() => { 
            onUnlinkDevice(); 
            onClose() }
          } 
          color="primary"
        >
          Poista
        </Button>
      </DialogActions>
    </Dialog>
  );
}
