import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import useStyles from "./styles";
import useEntosInfo from '../../../../../../../services/db/useEntosInfo';
import useToken from '../../../../../../../services/useToken';
import { audience } from '../../../../../../../services/db/api';

export default function SenserContent(props) {
  const classes = useStyles();
  let { info } = props;
  const { token } = useToken(audience);
  const {info: deviceInfo, error} = useEntosInfo(token, info.token);

  if (error) {
    console.error('Get info error', error);
  }
  
  if (deviceInfo && deviceInfo.metadata) {
     info = {...info, ...deviceInfo.metadata};
  }
  
  return (
    <>
      { info ?
        Object.entries(info).map(([key, entry]) => {
          if (key === 'token') { return null; }
          if (key === 'id') { return null; }
          if (key === 'device_id') { return null; } 
          return entry === null ? null : <DialogContentText className={classes.details} key={key} >{`${key}: ${entry}`}</DialogContentText>
        })
      : null}  
    </>
  )
}