import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import addOrganisation from '../../../../services/db/addOrganisation';

export default function AddCustomer(props) {

  const { onClose, open, token } = props;
  const [ name, setName ] = useState();

  const addCustomer = () => {
    console.log("addCustomer", name);
    if (!name) {
      onClose();
      return
    }
    addOrganisation(token, name)
      .then((orgId) => {
        if (!orgId) {
          const error = { msg: "No Org id" };
          throw error
        }
        const site = {orgId, orgName: name}
        onClose(site);
      })
      .catch((err) => {
        // Todo add error dialog
        console.log("Error", err);
      })
  };

  return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Lisää asiakas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Luo asiakas, jonka alle voit luoda kohteita ja yhteyshenkilöitä
          </DialogContentText>
          <TextField 
            autoFocus
            margin="dense"
            label="Organisaation nimi"
            fullWidth
            onChange={(event) => setName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">   
            Peruuta
          </Button>
          <Button variant="contained" onClick={addCustomer} color="primary">
            Lisää
          </Button>
        </DialogActions>
      </Dialog>
  );
}