import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    //maxWidth: 345,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',    
  },
  customerCardBody: {
    display: 'flex',
    alignItems: 'center', 
    
    padding: '0px',
    margin: '0px',
  },



  badges: {
    '& > *': {
      margin: theme.spacing(2)
    },
    marginLeft: theme.spacing(4)
  },
  link: {
    textDecoration: 'none',
    color: 'gray' 
  }
}));
