import React, { useEffect, useState, useCallback } from "react";
import Chart from "react-google-charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { deviceTypes } from "../../../../Constants";

// styles
import useStyles from "./styles";
import { useTheme } from "@material-ui/core/styles";

// components
import FanIcon from "../../../../icons/FanIcon";
import { CardHeader } from "@material-ui/core";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import IncidentDialog from "./components/incidentDialog/incidentDialog";
import TagChip from "../../../../components/TagChip/TagChip";
import Chip from "@material-ui/core/Chip";
import DeviceInfoDialog from "./components/deviceInfoDialog/DeviceInfoDialog";
import useCommentsForDevice from "../../../../services/db/useCommentsForDevice";
import EditDeviceDialog from "./components/editDeviceDialog/editDeviceDialog";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined"
import { useFilter } from "../../../../components/Header/components/useFilter";
import SettingsDialog from "./components/settingsDialog/settingsDialog";
import UnlinkDeviceDialog from "./components/unlinkDeviceDialog/unlinkDeviceDialog";
import EditIcon from '@material-ui/icons/CreateOutlined';
import delDeviceRelationToSite from "../../../../services/db/delDeviceRelationToSite";
import updateDeviceTagsAndDescription from "../../../../services/db/updateDeviceTagsAndDescription";

export default function Timeline(props) {
  const classes = useStyles();
  const theme = useTheme();
  const filter = useFilter();
  const {
    token,
    device,
    siteTags,
    update: updateAlertStateAndIncidents,
    siteId,
  } = props;
  const { deviceInfo, incidentTypes } = device;
  const {
    name: deviceName,
    type_id: deviceTypeId,
    device_id,
    site_tag_ids,
    description,
  } = deviceInfo[0];
  const deviceSpecificInfo = deviceInfo[1];
  //console.log("Device", device);
  const { comments, update: updateComments } = useCommentsForDevice(
    token,
    device_id,
  );

  const [incidentId, setIncidentId] = useState(props.incidentId);
  const [openDialog, setOpenDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [openUnlinkDeviceDialog, setOpenUnlinkDeviceDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedIncidentId, setSelectedIncidentId] = useState(false);
  const [chartData, setChartData] = useState();
  const [deviceTags, setDeviceTags] = useState();
  const [incidents, setIncidents] = useState({});
  const [commentsByType, setCommentsByType] = useState();
  const [selectedComments, setSelectedComments] = useState();
  const [deviceIdentifier, setDeviceIdentifier] = useState();
  const [deviceIdentifierName, setDeviceIdentifierName] = useState();
  const [chartHeight, setChartHeight] = useState(200);
  const [showChart, setShowChart] = useState(true);

  const minChartHight = 130;

  const handleOpenDialog = useCallback((incidentId, incidentTypeName) => {
    setSelectedIncidentId(incidentId);
    setSelectedComments(commentsByType[incidentTypeName]);
    setOpenDialog(true);
  },[commentsByType]);

  // This is for opening incidents from email
  useEffect(() => {
    if (incidentId && incidents[incidentId] && commentsByType) {
      const {incident_type_name} = incidents[incidentId];
      handleOpenDialog(incidentId, incident_type_name)
    }
  },[incidentId, incidents, commentsByType, handleOpenDialog])  

  useEffect(() => {
    if (siteTags) {
      const tags = [];
      siteTags.forEach((siteTag, index) => {
        if (site_tag_ids.includes(siteTag.site_tag_id)) {
          tags.push(siteTag);
        }
      });
      setDeviceTags(tags);
    }
  }, [siteTags, site_tag_ids]);

  useEffect(() => {
    if (incidentTypes && theme.palette.incidents) {
      const {
        prio1: prio1Color,
        prio2: prio2Color,
        inMonitor: inMonitorColor,
      } = theme.palette.incidents;
      const data = [
        [
          { type: "string", id: `incicent_type_name` },
          { type: "string", id: `incident_id` },
          { type: "string", role: "style" },
          { type: "date", id: "Start" },
          { type: "date", id: "End" },
        ],
      ];
      const incidentsData = {};
      Object.entries(incidentTypes).forEach(
        ([incident_type_name, incidents]) => {
          let color;
          switch (incidents[0].severity) {
            case "Prio 1":
              color = prio1Color;
              break;
            case "Prio 2":
              color = prio2Color;
              break;
            case "In monitoring":
              color = inMonitorColor;
              break;
            default:
              console.log(
                "Unknown severity in timeline:",
                incidents[0].severity,
              );
              color = inMonitorColor;
              break;
          }

          incidents.forEach(incident => {
            let itemColor = color.main;
            if (incident.closing_comment_id) {
              itemColor = color.light;
            } /* else if (incident.alert_state_now === 'ACTIVE_ALERT') {
            itemColor = color.dark;
          } */
            const startTime = new Date(incident.use_this_as_start_time * 1000);
            const endTime = incident.end_time
              ? new Date(incident.end_time * 1000)
              : new Date();
            data.push([
              `${incident_type_name}`,
              `${incident.incident_id}`,
              itemColor,
              startTime,
              endTime,
            ]);
            incidentsData[incident.incident_id] = incident;
          });
        },
      );
      setChartData(data);
      setIncidents(incidentsData);
    }
  }, [incidentTypes, theme.palette.incidents]);

  useEffect(() => {
    if (incidentTypes) {
      const commentsMap = {};
      Object.entries(incidentTypes).forEach(([name, incidentType]) => {
        const incidentTypeId = incidentType[0].incident_type_id;
        commentsMap[name] = [];
        if (comments) {
          comments.forEach(comment => {
            if (comment.incident_type_id === incidentTypeId) {
              commentsMap[name].unshift(comment);
            }
          });
        }
      });
      setCommentsByType(commentsMap);
    }
  }, [incidentTypes, comments]);

  useEffect(() => {
    if (deviceSpecificInfo) {
      switch (parseInt(deviceTypeId, 10)) {
        case deviceTypes.TopAir:
          setDeviceIdentifierName(deviceSpecificInfo.mac);
          setDeviceIdentifier(device_id);
          break;
        case deviceTypes.SF_MultiGuard_R2:
        case deviceTypes.SF_PressGuard_R4_1:
        case deviceTypes.SF_Ranger_1_3:
        case deviceTypes.SF_TempGuard_R3:
          setDeviceIdentifierName(deviceSpecificInfo.sf_device_id);
          setDeviceIdentifier(device_id);
          break;
        case deviceTypes.Senser:
          setDeviceIdentifierName(deviceSpecificInfo.imei);
          setDeviceIdentifier(device_id);
          break;
        default:
          setDeviceIdentifierName(device_id);
          setDeviceIdentifier(device_id);
          break;
      }
    }
  }, [deviceSpecificInfo, deviceTypeId, device_id]);

  const handleCloseDialog = (didComment, didClose) => {
    if (didClose) {
      updateAlertStateAndIncidents();
    }
    if (didComment) {
      updateComments();
    }
    setSelectedIncidentId(false);
    setOpenDialog(false);
    setIncidentId(undefined);
  };

  const handleOpenInfoDialog = () => {
    setOpenInfoDialog(true);
  };
  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const handleOpenSettingsDialog = () => {
    setOpenSettingsDialog(true);
  };
  const handleOpenUnlinkDeviceDialog = () => {
    setOpenUnlinkDeviceDialog(true);
  };
  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };
  const handleCloseSettingsDialog = didUpdate => {
    if (didUpdate) {
      updateAlertStateAndIncidents();
    }
    setOpenSettingsDialog(false);
  };
  const handleCloseUnlinkDeviceDialog = () => {
    setOpenUnlinkDeviceDialog(false);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  }

  const handelEditDevice = (selectedTags, description) => {
    // execute similar like delRelationToSite first. 
    updateDeviceTagsAndDescription( token, siteId, device_id, selectedTags , description )
    .then(updateAlertStateAndIncidents())
    .catch((err) => {
      // Todo add error dialog
      console.log("Error updateDeviceTagsAndDescription", err);
    });

   // then execute updateAlertStateAndIncidents()
   
  }

  const unlinkDevice = () => {
    delDeviceRelationToSite(token, deviceIdentifier)
      .then(updateAlertStateAndIncidents())
      .catch((err) => {
        // Todo add error dialog
        console.log("Error Unlinking device", err);
      });
  }

  // Force reerender of chart so hights are correct
  useEffect(() => {
    if (!showChart) {
      setShowChart(true);
    }
  }, [showChart]);

  const handelChartHeight = countedHeight => {
    // Min hight so it is posibly to see the hole tooltip TODO custom tooltip
    countedHeight =
      countedHeight < minChartHight ? minChartHight : countedHeight;
    if (chartHeight !== countedHeight) {
      setShowChart(false);
      setChartHeight(countedHeight);
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          avatar={
            <Chip
              onClick={handleOpenInfoDialog}
              clickable
              size="small"
              variant="outlined"
              color="default"
              icon={<FanIcon />}
              label={`${deviceName}, ${deviceIdentifierName}`}
            />
          }
          action={
            <>
            <IconButton
              aria-label="settings"
              onClick={event => {
                event.preventDefault();
                handleOpenUnlinkDeviceDialog();
              }}
            >
              {<DeleteOutlined />}
            </IconButton>
            <IconButton
              aria-label="settings"
              onClick={event => {
                event.preventDefault();
                handleOpenEditDialog();
              }}
            >
              {<EditIcon />}
            </IconButton>
            <IconButton
              aria-label="settings"
              onClick={event => {
                event.preventDefault();
                handleOpenSettingsDialog();
              }}
            >
              {<SettingsIcon />}
            </IconButton>
            </>
          }
        />
        <CardContent className={classes.content}>
          {description ? (
            <Typography
              className={classes.description}
            >{`\xa0\xa0${description}`}</Typography>
          ) : null}
          {deviceTags
            ? deviceTags.map((tag, index) => (
                <TagChip className={classes.chip} {...tag} key={index} />
              ))
            : null}
          {incidentTypes && showChart ? (
            <Chart
              className={classes.chart}
              height={chartHeight}
              chartLanguage="fi"
              chartType="Timeline"
              loader={
                <div>
                  Lataa aikajanaa...
                  <CircularProgress color="secondary" />
                </div>
              }
              data={chartData}
              options={{
                hAxis: {
                  minValue: new Date(filter.startDate()),
                  maxValue: new Date(filter.endDate()),
                },
                timeline: {
                  groupByRowLabel: true,
                  colorByRowLabel: true,
                  showBarLabels: false,
                  // avoidOverlappingGridLines: true,
                  barLabelStyle: { fontName: "Muli" },
                  rowLabelStyle: { fontName: "Muli" },
                },
              }}
              chartEvents={[
                {
                  eventName: "select",
                  callback: ({ chartWrapper }) => {
                    const selection = chartWrapper.getChart().getSelection();
                    if (
                      selection[0] !== undefined &&
                      selection[0].row !== undefined
                    ) {
                      const row = selection[0].row;
                      const sel_type = chartWrapper
                        .getDataTable()
                        .getValue(row, 0);
                      const sel_item = chartWrapper
                        .getDataTable()
                        .getValue(row, 1);
                      handleOpenDialog(sel_item, sel_type);
                    }
                  },
                },
                {
                  eventName: "ready",
                  callback: event => {
                    const id = event.chartWrapper.Lx;
                    if (!id) {
                      //console.warn("Did not get component id from chartWrapper");
                      return;
                    }
                    const container = document.getElementById(id);

                    // Use this log to see the html structure
                    //console.log("Chart", container);

                    // Get the chart hight form first rect if problems try to read the hight from the second svg
                    let countedHeight = 55;
                    const rectLabels = container.getElementsByTagName("rect");
                    Array.prototype.forEach.call(rectLabels, rect => {
                      if (rect.getAttribute("fill") === "none") {
                        countedHeight += parseInt(rect.getAttribute("height"));
                      }
                    });
                    handelChartHeight(countedHeight);

                    // change font on x-Axis
                    const textLabels = container.getElementsByTagName("text");
                    Array.prototype.forEach.call(textLabels, text => {
                      if (text.getAttribute("text-anchor") === "middle") {
                        text.setAttribute("font-family", "Muli");
                      }
                    });

                    // TODO confirmed incidents dimmed
                    // const rectLabels = container.getElementsByTagName('rect');
                    // console.log("RECT", rectLabels);
                  },
                },
              ]}
            />
          ) : null}
        </CardContent>
      </Card>
      {selectedIncidentId ? (
        <IncidentDialog
          token={token}
          open={openDialog}
          incident={incidents[selectedIncidentId]}
          comments={selectedComments}
          onClose={handleCloseDialog}
        />
      ) : null}
      <DeviceInfoDialog
        open={openInfoDialog}
        deviceInfo={deviceInfo}
        onClose={handleCloseInfoDialog}
      />
      <SettingsDialog
        token={token}
        open={openSettingsDialog}
        deviceInfo={deviceInfo}
        onClose={handleCloseSettingsDialog}
      />
      <UnlinkDeviceDialog
        title={`Irrota ${deviceName}, ${deviceIdentifier} tästä kohteesta?`}
        subTitle={"Mittaustietoja, insidenttejä ja kommentteja ei poisteta, mutta nämä kohdekohtaiset kohdistukset häviävät:"}
        promptText={`Irrottamisen jälkeen voit liittää ${deviceName}, ${deviceIdentifier} haluamallesi kohteelle, ja kohdistaa sitä uudelleen. Oletko varma että haluat jatkaa?`}
        open={openUnlinkDeviceDialog}
        onClose={handleCloseUnlinkDeviceDialog}
        onUnlinkDevice={unlinkDevice}
        tags={
          <>
            {deviceTags
            ? deviceTags.map((tag, index) => (
                <TagChip className={classes.chip} {...tag} key={index} />
              ))
            : null}
          </>
        }
      />
      <EditDeviceDialog open={openEditDialog} identifier={`${deviceName}, ${deviceIdentifierName}`} description={description} siteTags={siteTags} deviceTags={deviceTags} onClose={handleCloseEditDialog} edit={handelEditDevice}/>
    </>
  );
}
