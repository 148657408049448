import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import moment from "moment";

export default function HighVOC(props) {
  const { incident, data, options } = props;
  const { height, format } = options;
  const { hAxis, textTime } = format;

  const startTime = incident.startTime * 1000;
  const endTime = incident.endTime * 1000;

  const [chartData, setChartData] = useState(false);

  const parseToInt = val => {
    return parseInt(val) || 0;
  };

  useEffect(() => {
    const dataArray = [
      [
        { type: "date" },
        { type: "number", label: "VOC yläraja" },
        { type: "number", label: "Suurin puhallinnopeus kierroslkm" },
        { type: "number", label: "Pienin puhallinnopeus kierroslkm" },
        { type: "number", label: "Suurin mitattu VOC-pitoisuus" },
        { type: "number", label: "Pienin mitattu VOC-pitoisuus" },
        { type: "number", label: "Puhaltimen kierroslukumäärä" },
        { type: "number", label: "VOC-pitoisuus" },
        
      ],
    ];
    console.log("DATA", data);
    data.forEach(row => {
      dataArray.push([
        new Date(row.time * 1000),
        parseToInt(row.voc_upper_limit),
        parseToInt(row.motor_speed_max),
        parseToInt(row.motor_speed_min),
        parseToInt(row.voc_max),
        parseToInt(row.voc_min),
        parseToInt(row.motor_speed),
        parseToInt(row.voc),
        
      ]);
    });
    setChartData(dataArray);
  }, [data]);

  return (
    <>
      <Chart
        height={height}
        chartType="LineChart"
        data={chartData}
        options={{
          hAxis: {
            title: `Aikajakso: ${moment(startTime).format(textTime)} - ${moment(
              endTime,
            ).format(textTime)}`,
            format: hAxis,
            minValue: new Date(startTime),
            maxValue: new Date(endTime),
            gridlines: { color: "none" },
          },
          series: {
            0: { targetAxisIndex: 0, ...options.rangeSettings},
            1: { targetAxisIndex: 1, ...options.maxMin2Settings},
            2: { targetAxisIndex: 1, ...options.maxMin2Settings},
            3: { targetAxisIndex: 0, ...options.maxMin1Settings},
            4: { targetAxisIndex: 0, ...options.maxMin1Settings},
            5: { targetAxisIndex: 1, ...options.line2Settings},
            6: { targetAxisIndex: 0, ...options.line1Settings},
          },
          vAxis: {
            // TODO fix this dos not work for the moment 
            // Adds titles to each axis.
            0: { title: "VOC ppm (partikkeleita/miljoona)" },
            1: { title: "Kierroksia minuutissa" },
          },
        }}
      />
    </>
  );
}
