import { isSameDay } from 'date-fns';
import { predefinedFilters } from './useFilter';

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}
/*
export const defaultSelection = {
  startDate: defineds.oneMonthBack,
  endDate: defineds.endOfToday,
  key: 'selection',
  tag: '1Month'
}*/



export const customStaticRanges = createStaticRanges(Object.entries(predefinedFilters).map(([ key, entry ]) =>  {
  return {
    label: entry.label,
    range: () => ({
      startDate: (() => entry.startDate())(),
      endDate: (() => entry.endDate())(),
      key: "selection",
      tag: key
    })
  }
}));

export const defaultSelection = (filter) => {
  return {
    startDate: filter.startDate(),
    endDate: filter.endDate(),
    key: 'selection',
    tag: Object.keys(filter)[0]
  }
}

export const getSelected = (selected) => {
  if (predefinedFilters[selected.tag]) {
    return { 
      ...predefinedFilters[selected.tag],
      tag: selected.tag
    }
  }
  if (selected.tag === 'selection') {
    return {
      startDate: function() {return selected.startDate},
      endDate: function() {return selected.endDate},
      tag: "selection"
    };
  }

  return null;
} 
/*
export const customStaticRanges = createStaticRanges([
  {
    label: 'Tämä viikko',
    range: () => ({
      startDate: defineds.oneWeekBack,
      endDate: defineds.endOfToday,
      key: 'selection',
      tag: '1Week'
    }),
  },
  {
    label: '1kk',
    range: () => ({
      startDate: defineds.oneMonthBack,
      endDate: defineds.endOfToday,
      key: 'selection',
      tag: '1Month'
    }),
  },
  {
    label: '2kk',
    range: () => ({
      startDate: defineds.twoMonthBack,
      endDate: defineds.endOfToday,
      key: 'selection',
      tag: '2Month'
    }),
  },
  {
    label: '4kk',
    range: () => ({
      startDate: defineds.fourMonthBack,
      endDate: defineds.endOfToday,
      key: 'selection',
      tag: '4Month'
    }),
  },
  {
    label: '6kk',
    range: () => ({
      startDate: defineds.sixMonthBack,
      endDate: defineds.endOfToday,
      key: 'selection',
      tag: '6Month'
    }),
  },
]);

*/