import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import InputAdornment from '@material-ui/core/InputAdornment';

import FanIcon from '../../../../../icons/FanIcon';
import useDeviceTypes from '../../../../../services/db/useDeviceTypes';

export default function Devices(props) {

  const { select, token } = props;
  
  const { deviceTypes } = useDeviceTypes(token);

  const [ type, setType ] = useState(null);
  
  useEffect(() => {
    select(type);
  }, [select, type]);
  
  return (
    <Autocomplete
      value={type}
      options={deviceTypes ? deviceTypes : []}
      groupBy={(option) => option.brand}
      getOptionLabel={(option) => option.name}
      onChange={(event, type) => { 
        setType(type);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Laite / tietolähde"
          margin="normal"
          required
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
              <InputAdornment position="start">
                <FanIcon />
              </InputAdornment>
              
              </>
            ),
          }}
        />
      )}
  />
  );
}