import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    borderRadius: 15,
    //backgroundColor: theme.palette.background.light,
    //color: theme.palette.primary.contrastText,
    boxShadow: 3,
    padding: `0 px`,
    paddingBottom: 10,
    marginBottom: 10,
  },
  header: {
    paddingBottom: 2,
  },
  description: {
    fontSize: '1rem' 
  },
  chip: {
    marginTop: 7,
    marginRight: theme.spacing(1)
  },
  chart: {
    marginTop: 10
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    "&:last-child": {
      paddingBottom: 0
    },
    marginBottom: 0
  },
}));
