import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import ActiveIncidentIcon from "@material-ui/icons/WarningOutlined";
import OldIncidentIcon from "@material-ui/icons/WarningOutlined";

import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import CustomerIcon from "@material-ui/icons/PersonOutlined";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import BusinessIcon from "@material-ui/icons/Business";
import EditIcon from '@material-ui/icons/CreateOutlined';
import CardHeader from "@material-ui/core/CardHeader";
import FanIcon from "../../../../icons/FanIcon";
import IconButton from "@material-ui/core/IconButton";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography } from "../../../../components/Wrappers/Wrappers";

export default function SiteCard(props) {
  const classes = useStyles();

  const {onUpdate, site} = props;

  const {
    fullname,
    location,
    org_name,
    site_id,
    site_name,
    active_uncommented,
    old_uncommented,
    active_total,
    old_total,
    devices,
  } = site;

  const activeOpen = parseInt(active_uncommented) || 0;
  const activeTotal = parseInt(active_total) || 0;
  const oldOpen = parseInt(old_uncommented) || 0;
  const oldTotal = parseInt(old_total) || 0;

  const siteProps = {
    siteId: site_id,
    location,
    orgName: org_name,
    siteName: site_name,
    contactName: fullname,
  };
  return (
    <Link
      className={classes.link}
      to={{ pathname: "/app/viewSite", state: siteProps }}
    >
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              <BusinessIcon />
            </Avatar>
          }
          title={site_name}
          subheader={org_name}
          action={
            <IconButton
              aria-label="settings"
              onClick={event => {
                event.preventDefault();
                onUpdate(site);
              }}
            >
              {<EditIcon />}
            </IconButton>
          }
        />
        <CardContent className={classes.incidentCardBody}>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            spacing={1}
            wrap="nowrap"
          >
            <Grid className={classes.customer} item xs={2} zeroMinWidth>
              <Chip
                size="small"
                variant="outlined"
                icon={<CustomerIcon />}
                label={fullname}
              />
            </Grid>
            <Grid item xs={3} zeroMinWidth>
              <div>
                <Typography>
                  <LocationIcon />
                  {location}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} zeroMinWidth>
              <div className={classes.badges}>
                {activeTotal}
                <Badge badgeContent={activeOpen} color="primary">
                  <ActiveIncidentIcon />
                </Badge>
              </div>
            </Grid>
            <Grid item xs={2} zeroMinWidth>
              <div className={classes.badges}>
                {oldTotal}
                <Badge badgeContent={oldOpen} color="primary">
                  <OldIncidentIcon color="disabled" />
                </Badge>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.badges}>
                {devices}
                <Badge badgeContent={null} color="primary">
                  <FanIcon />
                </Badge>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
}
