import { useState, useEffect, useCallback  } from "react";
import { getEntosApi }from "./api";

export default function useEntosInfo(token, senserToken) {
  const [info, setInfo] = useState(false);
  const [error, setError] = useState(false);
  
  const update = useCallback(() => {
    if (token && senserToken) {
      getInfo(token, senserToken)
        .then((res) => {
          setInfo(res.data);
        })
        .catch((err) => {
          setError(err);
        })
    }
  }, [token, senserToken])
  useEffect(() => {
    update();

  }, [update, token]);
  return { info, update, error } 
}  

export function getInfo(token, senserToken) {
  const api = getEntosApi(token);
  return new Promise((resolve, reject) => {
    api.get('/info', {
      params: {
        token: senserToken
    }
    })
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
  })
}