import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import QrIcon from "../../../../../icons/QrIcon";
import ImeiIcon from "../../../../../icons/ImeiIcon";
import { Grid, Typography } from "@material-ui/core";

// styles
import useStyles from "./styles";
import addSenser from "../../../../../services/db/addSenser";

export default function Senser(props) {
  const classes = useStyles();
  const { submit, token } = props;

  //false = IMEI, true = QR 
  const [checked, setChecked] = useState(false);
  const [imei, setImei] = useState('');
  const [qr, setQr] = useState('');

  const add = useCallback(() => {
    addSenser(token, {imei: imei === ''? undefined : imei, qr: qr === '' ? undefined : qr })
      .then((res) => {
        console.log('added', res);
      })
      .catch((err) => {
        // Todo add error dialog
        console.log("Senser error", err);
      })
  }, [imei, qr, token]);

  useEffect(() => {
    if (submit) {
      console.log('submit in senser');
      add();
    }
  },[submit, add])

  

  const change = event => {
    // QR
    if (checked) {
      setQr(event.target.value);
    }
    // IMEI 
    else {
      setImei(event.target.value);
    }
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>IMEI</Grid>
        <Grid item>
          <Switch checked={checked} onChange={handleChange} />
        </Grid>
        <Grid item>QR</Grid>
      </Grid>
      {checked ? (
        <TextField
          fullWidth
          required
          value={qr}
          variant="filled"
          label="QR"
          placeholder="Syötä QR linki"
          onChange={change}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <QrIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      ) : (
        <TextField
          fullWidth
          required
          value={imei}
          variant="filled"
          label="IMEI"
          placeholder="Syötä IMEI koodi"
          onChange={change}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <ImeiIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    </>
  );
}
