import React from "react";
import Box from '@material-ui/core/Box';
import classNames from "classnames";

// styles
import useStyles from "./styles";

export default function ColorDescription(props) {
  const classes = useStyles();

  const { level } = props;
  const height = 18;
  const width = 44;

  switch (level) {
    case "Prio 1":
      return ( 
      <Box className={classes.box}>
        PRIO 1:
        <Box 
          height={height}
          width={width} 
          className={classNames(classes.prio1, classes.colorBox)}>
        </Box>
      </Box>
      );
    case "Prio 2":
      return ( 
      <Box className={classes.box}>
        PRIO 2:
        <Box 
          height={height}
          width={width}
          className={classNames(classes.prio2, classes.colorBox)}>
        </Box>
      </Box>
      );
    case "In monitoring":
      return (
      <Box className={classes.box}>
        SEURANNASSA:
        <Box
        height={height}
        width={width}
        className={classNames(classes.inMonitoring, classes.colorBox)}>
        </Box>
      </Box>
      );
    default:
      return null;
  }
}






