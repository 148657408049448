import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const overrides = {
  typography: {
    fontFamily: [
//      'Courier', // This is the place to override the fonts! Cervi H1 should be 'Ginger Bold', 
      //while most of the other should be 'Ginger Regular', some captions, and tags event 'Ginger Light'
      // The Cervi fonts are not available as default.... can they be imported?
      'Muli',
      'Roboto',   
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    }
  },
};

const theme = {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};

export default theme;


