import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import BuildingIcon from "@material-ui/icons/Apartment";
import StaircaseIcon from "@material-ui/icons/TransferWithinAStation";
import FloorIcon from "@material-ui/icons/ClearAll";
import DoorIcon from "@material-ui/icons/MeetingRoom";
import Chip from "@material-ui/core/Chip";

// styles
import useStyles from "./styles";

export default function Tags(props) {
  const classes = useStyles();

  const { add, tag } = props;
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (tag.tags) {
      setTags(tag.tags);
    }
  }, [tag.tags])

  const getIcon = () => {
    switch (tag.id) {
      case '1':
        return <BuildingIcon />;
      case '2':
        return <StaircaseIcon />;
      case '3':
        return <FloorIcon />;
      case '4':
        return <DoorIcon />;
      default:
        return <BuildingIcon />;
    }
  };

  const getPlaceholder = () => {
    switch (tag.id) {
      case '1':
        return "Syötä rakennuksen nimi, paina enter ja syötä seuraava";
      case '2':
        return "A, B, C, jne.";
      case '3':
        return "Kellari, 1, 2, 3, ullakko, katto, jne.";
      case '4':
        return "Pesuhuone, keittiö, jne.";
      default:
        return "";
    }
  };

  return (
    <>
      <Autocomplete
        value={tags}
        multiple
        options={[]}
        freeSolo
        size="small"
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="filled"
            label={tag.name}
            placeholder={getPlaceholder()}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment
                    className={classes.adornmentLogo}
                    position="start"
                  >
                    {getIcon()}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={(event, newTag) => {
          setTags(newTag);
          add(tag.id, newTag.map((tag) => {return {name: tag}}));
        }}
      />
    </>
  );
}
