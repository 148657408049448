import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FilterIcon from "@material-ui/icons/FilterListOutlined";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import AccountIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import InputBase from "@material-ui/core/InputBase";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import classNames from "classnames";
import { useFilter } from "./components/useFilter";
import moment from "moment";
import { getSearchResult } from "../../services/db/getSearchResult";
import useToken from "../../services/useToken";
import { audience } from "../../services/db/api";
import { useHistory } from "react-router-dom";
import { getSearchInfo } from "../../services/db/getSearchInfo";

// styles
import useStyles from "./styles";
import { useTheme } from "@material-ui/core/styles";

// components
import { Typography } from "../Wrappers/Wrappers";
import ProfileMenu from "./components/ProfileMenu";
import FilterMenu from "./components/FilterMenu";
import Logo from "../../images/cervi_logo.svg";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

export default function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { dateFormat } = theme;

  // global
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const filter = useFilter();
  const { token } = useToken(audience);
  let history = useHistory();

  // local
  const [profileMenu, setProfileMenu] = useState(null);
  const [filterMenu, setFilterMenu] = useState(null);

  //Search
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");

  useEffect(() => {
    if (searchInputValue !== "") {
      getSearchResult(token, searchInputValue).then(result => {
        setSearchOptions(result);
      });
    } else {
      setSearchOptions([]);
    }
  }, [searchInputValue, token]);

  const openSelectedSite = async option => {
    if (option && option.id) {
      let info = {};
      try {
        info = await getSearchInfo(token, option.id);
      } catch (err) {
        console.log("Error getting searchInfo:", err);
        // Todo handel error
      }
      const siteProps = {
        siteId: option.id,
        location: info.location || "",
        orgName: info.org_name || "",
        siteName: info.site_name || "",
        contactName: info.fullname || "",
      };

      history.push({ pathname: "/app/search", state: siteProps });
    }
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>

        <img src={Logo} alt="Logo" width="100px" />

        <Typography variant="h6" weight="medium" className={classes.logotype}>
          Ilmatilavalvomo
        </Typography>

        <div className={classes.grow} />

        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => {
              setSearchOpen(!isSearchOpen);
            }}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <Autocomplete
            autoComplete
            filterSelectedOptions
            value={null}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            options={searchOptions}
            noOptionsText="Ei vaihtoehtoja"
            onChange={(event, newValue) => {
              openSelectedSite(newValue);
            }}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === "input") {
                setSearchInputValue(newInputValue);
              }
              if (reason === "reset") {
                setSearchOptions([]);
              }
            }}
            renderInput={params => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                placeholder="Etsi asiakas tai kohde…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            )}
          />
        </div>

        {filter ? (
          <Typography
            className={classes.dateRange}
            variant="h6"
            weight="medium"
          >
            {`${moment(filter.startDate()).format(dateFormat.basic)} - ${moment(
              filter.endDate(),
            ).format(dateFormat.basic)}`}
          </Typography>
        ) : null}
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="filter-menu"
          onClick={e => setFilterMenu(e.currentTarget)}
        >
          <FilterIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <FilterMenu
          id="filter-menu"
          open={Boolean(filterMenu)}
          anchorEl={filterMenu}
          onClose={() => setFilterMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.filterMenu }}
          disableAutoFocusItem
        />

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <ProfileMenu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        />
      </Toolbar>
    </AppBar>
  );
}
