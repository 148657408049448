import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import delUser from "../../../services/db/delUser";
import editUser from '../../../services/db/editUser';

export default function UpdateUser(props) {

  const { onClose, open, token, user, onError } = props;
  const { id, fullname } = user;
  const [ name, setName ] = useState();

  useEffect(() => {
    if(fullname) {
      setName(fullname);
    }
  }, [fullname]); 

  const handelDelUser = () => {  
    delUser(token, id)
      .then(onClose(true))
      .catch((err) => {
        if (err.error && err.error)  {
          handelError(err.error);
        }
      })
  }

  const handelUpdateUser = () => {  
    if (!name) {
      onClose();
      return
    }
    editUser(token, {...user, fullname: name })
      .then(onClose(true))
      .catch((err) => {
        // Todo add error dialog
        console.log("Error", err);
      })
  };

  const handelError = (error) => {
    if (error.code === "23503"){
      onError();
    } else {
      console.log("Error deleting user:", error);
    }  
  }

  return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Henkilön muokkaus</DialogTitle>
        <DialogContent>
          <DialogContentText>
             Muokkaa henkilöä
          </DialogContentText>
          <TextField 
            autoFocus
            margin="dense"
            label="Uusi nimi"
            fullWidth
            defaultValue={fullname}
            onChange={(event) => setName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handelDelUser} color="secondary" startIcon={<DeleteOutlined />} >
            Poista henkilö kokonaan
          </Button>
          <Button onClick={onClose} color="primary">   
            Peruuta
          </Button>
          <Button variant="contained" onClick={handelUpdateUser} color="primary">
            Muokkaa
          </Button>
        </DialogActions>
      </Dialog>
  );
}