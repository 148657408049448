import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";

import InfoIcon from "@material-ui/icons/Info";
import Tags from "./components/tags";



export default function EditDialogDevice(props) {
  const { onClose, open, identifier, description: oldDescription, siteTags, deviceTags, edit } = props;

  const [selectedTags, setSelectedTags] = useState([]);
  const [description, setDescription] = useState();

  const addSiteTag = tag => {
    const list = [...selectedTags];
    list.push(tag.site_tag_id);
    setSelectedTags(list);
  };

  const removeSiteTag = tag => {
    const list = [...selectedTags];
    const index = list.indexOf(tag.site_tag_id);
    if (index > -1) {
      list.splice(index, 1);
    }
    setSelectedTags(list);
  };

  const submitSite = () => {
    edit(selectedTags, description);
    onClose();
  };

  const closeAttachDialog = attached => {
    onClose();
  };

  useEffect(() => {
    if (oldDescription) {
      setDescription(oldDescription);
    }
  }, [oldDescription])

  useEffect(() => {
    if (deviceTags) {
      const tagList = [];
      deviceTags.forEach(tag => {
        tagList.push(tag.site_tag_id);
      });
      setSelectedTags(tagList);
    }
  }, [deviceTags])

  return (
    <Dialog
      open={open}
      onClose={() => closeAttachDialog(false)}
      maxWidth={"sm"}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={submitSite}>
        <DialogTitle id="form-dialog-title">{identifier}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Kohdista tämän laitteen mittaustiedot tageilla. (Kohdetta muokkaamalla voit myös lisätä ja poistaa tageja)
          </DialogContentText>
          <Tags siteTags={siteTags} deviceTags={deviceTags} add={addSiteTag} remove={removeSiteTag} />

          <TextField
            defaultValue={description}
            label="Lisätietoa"
            helperText="Kuvaa mihin tämä laite on asennettu, ja mitä se mittaa."
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InfoIcon />
                </InputAdornment>
              ),
            }}
            onChange={event => setDescription(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeAttachDialog(false)} color="primary">
            Peruuta
          </Button>
          <Button variant="contained" type="submit" color="primary">
            Päivitä
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
