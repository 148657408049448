import React from "react";
import Menu from "@material-ui/core/Menu";

import MenuItem from "@material-ui/core/MenuItem";
import AccountIcon from "@material-ui/icons/Person";


import classNames from "classnames";

import { useAuth0 } from "@auth0/auth0-react";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../Wrappers/Wrappers";

export default function ProfileMenu(props) {
  const classes = useStyles();

  const { user, logout } = useAuth0();

  return (
        <Menu
          {...props}
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h5" >
              {user.nickname ? user.nickname : user.name }
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={() => {
              console.log("Name:", user.name);
              console.log("email:", user.name);
            }}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profiili
          </MenuItem> 
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() =>  logout({ returnTo: window.location.origin })} //signOut(userDispatch, props.history)}
            >
              Kirjaudu ulos
            </Typography>
          </div>
        </Menu>
  );
}
