import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import moment from "moment";
import localization from "moment/locale/fi";

export default function FewerSampleThanEpected(props) {
  moment.locale("fi");
  moment.updateLocale("fi", localization); // TODO: This should probably be placed somewhere globally??!

  const { incident, data, options } = props;
  const { height, format } = options;
  const { hAxis, textTime } = format;

  const startTime = incident.startTime * 1000;
  const endTime = incident.endTime * 1000;

  const [chartData, setChartData] = useState(false);

  const parseToInt = val => {
    return parseInt(val) || 0;
  };

  useEffect(() => {
    const dataArray = [
      [{ type: "date" }, { type: "number", label: "Mittauksia" }],
    ];
    data.forEach(row => {
      dataArray.push([
        new Date(row.time * 1000),
        parseToInt(parseToInt(row.samples)),
      ]);
    });
    setChartData(dataArray);
  }, [data]);

  return (
    <>
      <Chart
        height={height}
        chartType="LineChart"
        data={chartData}
        options={{
          hAxis: {
            title: `Aikajakso: ${moment(startTime).format(textTime)} - ${moment(
              endTime,
            ).format(textTime)}`,
            minValue: new Date(startTime),
            maxValue: new Date(endTime),
            format: hAxis,
            gridlines: { color: "none" },
          },
          series: {
            0: options.line1Settings,
          },
          vAxis: {
            title: "Mittauksia",
          },
        }}
      />
    </>
  );
}
