import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import ActiveIncidentIcon from '@material-ui/icons/WarningOutlined';
import OldIncidentIcon from '@material-ui/icons/WarningOutlined';
import EditIcon from '@material-ui/icons/CreateOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import { Link } from "react-router-dom";

// styles
import useStyles from "./styles";

// components
import { Badge } from "../../../../components/Wrappers/Wrappers";
import FanIcon from "../../../../icons/FanIcon";
import IconButton from "@material-ui/core/IconButton";

export default function CustomerCard(props) {
  const classes = useStyles();

  const { onUpdate, customer } = props;

  const {
    active_uncommented,
    old_uncommented,
    active_total,
    old_total,
    device_count,
    org_id,
    org_name,
    site_count } = customer;

    const activeOpen = parseInt(active_uncommented) || 0;
    const activeTotal = parseInt(active_total) || 0;
    const oldOpen = parseInt(old_uncommented) || 0;
    const oldTotal = parseInt(old_total) || 0;
    const deviceCount = parseInt(device_count) || 0;

  return (
    <Link className={classes.link} to={{ pathname: "/app/sites", state: { orgId: org_id, orgName: org_name } }} >
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              <PeopleOutlineIcon />
            </Avatar>
          }
          title={org_name}
          subheader={`Kohteita ${site_count}`}
          action={
            <IconButton
              aria-label="settings"
              onClick={event => {
                event.preventDefault();
                onUpdate(customer);
              }}
            >
              {<EditIcon />}
            </IconButton>
          }
        />
        <CardContent className={classes.customerCardBody} >
          <Grid container spacing={1}>
            <Grid item xs={5}>
            </Grid>
            <Grid item xs={2}>
              <div className={classes.badges}>
                {activeTotal}
                <Badge badgeContent={activeOpen} color="primary">
                  <ActiveIncidentIcon />
                </Badge>
                </div>
            </Grid>
            <Grid item xs={2}>
              <div className={classes.badges}>
                {oldTotal}
                <Badge badgeContent={oldOpen} color="primary">
                  <OldIncidentIcon color="disabled" />
                </Badge>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.badges}>{deviceCount}<Badge badgeContent={null} color="primary">
              <FanIcon />
              </Badge></div>
            </Grid>
          </Grid>
        </CardContent>       
      </Card>
    </Link>
  );
}