import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    tagChip: {
        fontSize: "0.75rem",
      
    },
    tagName: {
        fontSize: "0.75rem",
    },
    filterButton: {
     
    },

  
  
}));
