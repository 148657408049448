import React, { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessIcon from '@material-ui/icons/Business'
import LocationIcon from "@material-ui/icons/LocationOnOutlined"
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Customer from './components/customer';
import ContactPerson from './components/contactPerson';
import Tags from './components/tags';
import Typography from "@material-ui/core/Typography";

import updateSite from '../../../../services/db/updateSite';
import useTagTypes from '../../../../services/db/useTagTypes';
import useTagsForSite from "../../../../services/db/useTagsForSite";

// styles
import useStyles from "./styles";

export default function UpdateSite(props) {
  const classes = useStyles();

  const { onClose, open, token, site } = props;
  
  const {
    fullname,
    location,
    org_name,
    site_id,
    site_name,
    org_id,
    user_id
  } = site;
  const org = { org_id, org_name};

  const { tags: oldSiteTags} = useTagsForSite(token, site_id);

  const { tags: tagTypes } = useTagTypes(token);
  const [tags, setTags] = useState();

  useEffect(() => {
    let _tags = [];
    if (tagTypes) {
      tagTypes.forEach(tagType => {
        tagType['tags'] = []
        tagType['oldTags'] = []
        if (oldSiteTags) {
          oldSiteTags.forEach(oldTag => {
            if (oldTag.type_id === tagType.id) {
              tagType['tags'].push(oldTag.name);
              tagType['oldTags'].push({name: oldTag.name, siteTagId: oldTag.site_tag_id});
            }
          })
        }
        _tags.push(tagType);
      });
      setTags(_tags);
    }
  }, [oldSiteTags, tagTypes])

  useEffect(() => {
    if (site_name) {
      name.current = site_name;
    }
  }, [site_name])

  useEffect(() => {
    if (location) {
      editedLocation.current = location;
    }
  }, [location])

  const name = useRef();
  const customer = useRef(); 
  const editedLocation = useRef();
  const user = useRef();
  const siteTags = useRef({});

  const addSiteTags = (id, tags) => {
    if(!siteTags.current[id]) {
      siteTags.current[id] = [];
    }
    tags.forEach((tag) => {

      siteTags.current[id].forEach((oldTag) => {
        if (oldTag.name === tag.name) {
          if (oldTag.siteTagId) {
            tag.siteTagId = oldTag.siteTagId;
          }
        }
      })
    });
    siteTags.current[id] = tags;
  }

  const submitSite = () => { 
    updateSite(token, site_id, name.current, editedLocation.current, customer.current, user.current, siteTags.current)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        // Todo add error dialog
        console.log("Error", err);
      })
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={submitSite}>
      <DialogTitle id="form-dialog-title">Kohteen määrittely</DialogTitle>       
      <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField 
                defaultValue={site_name}
                label="Kohteen nimi" 
                helperText="Esim AsOy:n tai KOy:n nimi"
                fullWidth
                margin="normal"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => name.current = event.target.value}    
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Customer token={token} add={(data) => customer.current = data} org={org}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ContactPerson add={(data) => user.current = data} token={token} user={{id: user_id, fullname: fullname}}/>
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                defaultValue={location}
                label="Kohteen sijainti"
                helperText="Katuosoite + katunumero, kaupunki"
                required
                onChange={(event) => editedLocation.current = event.target.value}    
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationIcon />
                    </InputAdornment>
                  ),
              }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid> 
            <Grid item xs={12} sm={10}>  
              <Typography  className={classes.subheader} >Määrittele tälle kohteelle tageja, jolla haluat tunnistaa mittaustiedot ja erotella niitä toisistaan. 
                Jos kohteessa on esim. vain yksi rakennus, niin rakennus-tagiä ei tarvitse/kannata määritellä. Tagit ovat vapaaehtoiset.
              </Typography>      
            </Grid>
            { tags ? tags.map((tag, index) => {
              if (tag.id && tag.oldTags && tag.oldTags.length > 0) {
                addSiteTags(tag.id, tag.oldTags);
              }
              
              return (
                <Grid key={index} item xs={12} sm={10}>  
                  <Tags tag={tag} add={addSiteTags}/>  
                </Grid>
              )
            }) : null}
          </Grid>
        </DialogContent>
      
        <DialogActions>
          <Button onClick={onClose} color="primary">   
            Peruuta
          </Button>
          <Button variant="contained" type="submit" color="primary">
            Tallenna
          </Button>
        </DialogActions>
        </form>
      </Dialog>
  );
}