import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import DialogContentText from "@material-ui/core/DialogContentText";
import PressureIcon from "../../../../../icons/PressureIcon";

// styles
import useStyles from "./styles";

export default function PressGuard(props) {
  const classes = useStyles();

  const { update, settings } = props;

  const [pressLowerLimit, setPressLowerLimit] = useState();
  const [pressUpperLimit, setPressUpperLimit] = useState();

  useEffect(() => {
    update({
      pressLowerLimit,
      pressUpperLimit,
    });
  }, [update, pressLowerLimit, pressUpperLimit]);

  useEffect(() => {
    if (settings) {
      const {
        pressure_difference_lower_limit,
        pressure_difference_upper_limit,
      } = settings;
      setPressLowerLimit(pressure_difference_lower_limit);
      setPressUpperLimit(pressure_difference_upper_limit);
    }
  }, [settings]);

  return (
    <>
      <DialogContentText>Määrittele sallittu mittausalue 3:</DialogContentText>

      <Grid container spacing={2}>
        <Grid item>
          <TextField
            className={classes.pressGuardInput}
            label="Paine-eron alaraja"
            value={pressLowerLimit ? pressLowerLimit : ""}
            type="number"
            size="small"
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PressureIcon />
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">Pa</InputAdornment>,
            }}
            onChange={event =>
              setPressLowerLimit(
                event.target.value !== "" ? parseInt(event.target.value) : undefined,
              )
            }
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.pressGuardInput}
            label="Paine-eron yläraja"
            value={pressUpperLimit ? pressUpperLimit : ""}
            type="number"
            size="small"
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PressureIcon />
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">Pa</InputAdornment>,
            }}
            onChange={event =>
              setPressUpperLimit(
                event.target.value !== "" ? parseInt(event.target.value) : undefined,
              )
            }
          />
        </Grid>
      </Grid>
      <DialogContentText>
        Raja-arvot asetetaan tallennushetkellä, ja ovat voimassa kunnes niitä on säädetty uudestaan. 
        Häiriötilannetunnistus ajetaan kerran tunnissa, ja vertailee mittaustiedot mittaushetkellä voimassa olevien raja-arvojen kanssa.
        Raja-arvojen säätäminen ei vaikuta takautuvasti tunnistetuihin häiriöihin. 
      </DialogContentText>
    </>
  );
}
