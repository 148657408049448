import React, { useState, useEffect } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";

// components
import TagChip from "../../../../../components/TagChip/TagChip";

// styles
import useStyles from "./styles";

export default function Tags(props) {
  const classes = useStyles();

  const { selected, add, remove, tags } = props;

  const [siteTags, setSiteTags] = useState();

  useEffect(() => {
    if (!tags) {
      return;
    }
    const tagList = {};
    for (const tag of tags) {
      if (!tagList[tag.type_id]) {
        tagList[tag.type_id] = {
          name: tag.type_name,
          tags: [],
        };
      }
      tag["selected"] = selected.includes(tag.site_tag_id);
      tagList[tag.type_id].tags.push(tag);
    }
    setSiteTags(tagList);
  }, [tags, selected]);

  return (
    <>
      {siteTags
        ? Object.entries(siteTags).map((entry, index) => {
            const typeName = entry[1].name;
            const typeTags = entry[1].tags;
            return (
              <React.Fragment key={index}>
                <DialogContentText className={classes.tagTypeContentText}>
                  {`${typeName}:`}
                </DialogContentText>
                <DialogContent className={classes.tagTypeContent}>
                  <Grid container direction="row" spacing={1}>
                    {typeTags.map((tag, index) => {
                      return (
                        <Grid item key={index}>
                          <TagChip
                            {...tag}
                            onClick={() => {
                              let obj = { ...siteTags };
                              let items = [...obj[tag.type_id].tags];
                              let item = { ...items[index] };
                              item.selected = tag.selected ? false : true;
                              items[index] = item;
                              obj[tag.type_id].tags = items;
                              setSiteTags(obj);
                              item.selected ? add(tag) : remove(tag);
                            }}
                            clickable
                            color={tag.selected ? "primary" : "default"}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </DialogContent>
              </React.Fragment>
            );
          })
        : null}
    </>
  );
}

Tags.defaultProps = {
  selected: [],
};
