import { useState } from "react";
import { singletonHook } from 'react-singleton-hook';
import moment from 'moment';

import {
  endOfDay,
  addMonths,
  addWeeks,
} from 'date-fns';

const defineds = {
  endOfToday: endOfDay(new Date()),
  oneWeekBack: endOfDay(addWeeks(new Date(), -1)),
  twoWeekBack: endOfDay(addWeeks(new Date(), -2)),
  treeWeekBack: endOfDay(addWeeks(new Date(), -3)),
  oneMonthBack: endOfDay(addMonths(new Date(), -1)),
  twoMonthBack: endOfDay(addMonths(new Date(), -2)),
  treeMonthBack: endOfDay(addMonths(new Date(), -3)),
  fourMonthBack: endOfDay(addMonths(new Date(), -4)),
  fiveMontBack: endOfDay(addMonths(new Date(), -5)),
  sixMonthBack: endOfDay(addMonths(new Date(), -6)),
};

export const predefinedFilters = {
  "1Week": {
    label: 'Viimeisin viikko',
    startDate: () => defineds.oneWeekBack,
    endDate: () => defineds.endOfToday
  },
  "1Month": {
    label: 'Viimeisin kk',
    startDate: () => defineds.oneMonthBack,
    endDate: () => defineds.endOfToday
  },
  "2Month": {
    label: 'Viim. 2 kk',
    startDate: () => defineds.twoMonthBack,
    endDate: () => defineds.endOfToday
  },
  "4Month": {
    label: 'Viim. 4 kk',
    startDate: () => defineds.fourMonthBack,
    endDate: () => defineds.endOfToday
  },
  "6Month": {
    label: 'Viim. 6 kk',
    startDate: () => defineds.sixMonthBack,
    endDate: () => defineds.endOfToday
  }
}

const defaultFilter = {
  ...predefinedFilters['1Month'],
  tag: '1Month'
};

let globalSetFilter;

export const useFilter = singletonHook((defaultFilter), () => {
  const [filter, setFilter] = useState(defaultFilter); 
  globalSetFilter = setFilter;
  return filter;
})

export const setFilter = filter => globalSetFilter(filter);

export const epochFormat = ((filter) => {
  const startDate = `${moment(filter.startDate()).format('YYYY-MM-DD')} 00:00:00`;
  const unixStartDate = moment(startDate).unix();
  const endDate = `${moment(filter.endDate()).format('YYYY-MM-DD')} 23:59:59`;
  const unixEndDate = moment(endDate).unix();

  return {
    startDate: unixStartDate,
    endDate: unixEndDate
  }
});