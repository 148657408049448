import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import useUsers from "../../services/db/useUsers";
import moment from "moment";
import localization from "moment/locale/fi";

// context
import useToken from "../../services/useToken";
import { audience } from "../../services/db/api";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Card } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/CreateOutlined';
import UpdateUser from "./components/UpdateUser";
import DeleteError from "./components/DeleteError/DeleteError";

export default function Users() {
  moment.locale("fi");
  moment.updateLocale("fi", localization);

  const { token } = useToken(audience);
  const { users, update: updateUsers } = useUsers(token);
  const [deleteErrorOpen, setDeleteErrorOpen] = useState(false);

  const [user, setUser] = useState({});

  const handleUpdateOpen = (userObj) => {
    setUser(userObj);
  }

  const handleUpdateClose = (shallUpdate) => {
    setUser({});
    if( shallUpdate ) {
      updateUsers();
    }
  }

  const handelDeleteErrorOpen = () => {
    setUser({});
    setDeleteErrorOpen(true);
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <PageTitle title="Henkilöt" />
        </Grid>
        { users? Object.entries(users).map(([userId, user]) => 
          <Grid key={userId} item xs={12}>
          <Card>
              <CardHeader 
                title={user.fullname} 
                avatar={
                  <Avatar aria-label="recipe" >
                    <PersonOutlineOutlinedIcon/> 
                  </Avatar>
                }
                //subheader="Subtitle"
                action={
                  <IconButton
                    aria-label="settings"
                    onClick={event => {
                      event.preventDefault();
                      handleUpdateOpen(user);
                    }}
                  >
                    {<EditIcon />}
                  </IconButton>
                }
                            />
            </Card>
          </Grid>
        ): null } 
      </Grid>
      <UpdateUser token={token} open={Object.keys(user).length === 0 ? false : true} onClose={handleUpdateClose} onError={handelDeleteErrorOpen} user={user} />
      <DeleteError  title="Henkilön poisto epäonnistui" bodyText={`Hän on yhteyshenkilönä vähintään yhdessä kohteessa. Muuta näiden kohteiden yhteyshenkilö ensin.`}  open={deleteErrorOpen} onClose={() => setDeleteErrorOpen(false)} />
    </>
  );
}
