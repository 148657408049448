import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import { epochFormat } from "../../components/Header/components/useFilter";

export default function useAlertStatesAndIncidents(
  token,
  siteId,
  filter,
  dateFilter,
) {
  const [incidents, setIncidents] = useState(false);
  const [error, setError] = useState(false);

  const update = useCallback(() => {
    if (token) {
      getAlertStatesAndIncidents(token, siteId, filter, dateFilter)
        .then(res => {
          setIncidents(res);
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [token, siteId, filter, dateFilter]);

  useEffect(() => {
    update();
  }, [update, token, filter, dateFilter]);

  return { incidents, update, error };
}

export function getAlertStatesAndIncidents(token, siteId, filter, dateFilter) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    const { startDate, endDate } = epochFormat(dateFilter);
    api
      .get("/alertStatesAndIncidentsForSite", {
        params: {
          siteId,
          siteFilter: filter, // must bee json object { id: name }
          filter_start_date: startDate,
          filter_end_date: endDate,
        },
      })
      .then(res => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
