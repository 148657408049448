import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  listDiv: {
    marginTop: 10,
    height: 100,
    overflow: "auto"
  },
  list: {
    paddingTop: 0
  },
 
}));