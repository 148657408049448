import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import tableIcons from "./components/tableIcons";
import useDeviceDetails from "../../services/db/useDeviceDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterDialog from "./components/filterDialog/filterDialog";
import moment from "moment";
import localization from "moment/locale/fi";

// context
import useToken from "../../services/useToken";
import { audience } from "../../services/db/api";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddDevice from "./components/addDevice/addDevice";

export default function Devices() {
  moment.locale("fi");
  moment.updateLocale("fi", localization);

  const { token } = useToken(audience);
  const { devices } = useDeviceDetails(token);
  const [columns, setColumns] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    const nameMap = {
      type_id: "Laitetyypin id",
      type_name: "Laite",
      device_id: "Laitteen id",
      site_id: "Kohteen tunniste",
      site_name: "Kohde",
      created: "Luotu",
      sim_number: "SIM-kortin numero",
      url: "Url",
      org_name: "Asiakas",
      device_detail: "Laitteen tunniste",
    };

    const excluded = ["type_id", "org_id"];
    const hided = ["device_id", "site_id", "created", "sim_number", "url"];

    if (devices) {
      const columnsArray = [];
      const dataArray = [];
      devices.forEach((device, deviceIndex) => {
        const data = {};
        Object.entries(device).forEach(([key, value]) => {
          if (excluded.includes(key)) {
            return;
          }
          if (deviceIndex === 0) {
            let columnsObj;
            if (hided.includes(key)) {
              columnsObj = { title: nameMap[key], field: key, hidden: true };
            } else {
              columnsObj = { title: nameMap[key], field: key, hidden: false };
            }
            columnsArray.push(columnsObj);
          }
          if (key === "created") {
            value = moment(value).format("DD.MM.YYYY");
          }
          if (key === "url") {
            if (device["type_name"].includes('Topair') && value) {
              value = `http://${value.replaceAll(':', '.')}`;
            }
            if (device["type_name"].includes('Senser') && value) {
              value = `https://www.valvonnassa.fi/device/wmu5?token=${value}`;
            }
            if (device["type_name"].includes('SF') && value) {
              value = `https://fi.foxeriot.com/#/devices/${value}`;
            }
          }
          data[key] = value;
        });
        dataArray.push(data);
      });
      setColumns(columnsArray);
      setDeviceData(dataArray);
    }
  }, [devices]);

  const handleCloseFilterDialog = newColumns => {
    if (newColumns) {
      setColumns(newColumns);
    }
    setOpenFilterDialog(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false); 
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <PageTitle title="Laitteet" />
        </Grid>
        <Grid item xs={5}><Fab  variant="extended" color="primary" aria-label="add" onClick={handleClickOpen}><AddIcon />{`Lisää laite`}</Fab></Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Cervi laitevienti"
            icons={tableIcons}
            columns={columns}
            data={deviceData}
            options={{
              showTitle: false,
              exportButton: true,
              sorting: true,
              paging: false,
              filtering: true,
            }}
            actions={[
              {
                icon: () => <FilterListIcon />,
                tooltip: "Suodatus",
                onClick: () => setOpenFilterDialog(true),
                isFreeAction: true,
              },
            ]}
            localization={{
              toolbar: {
                searchPlaceholder: "Etsi",
                searchTooltip: "Etsi",
                exportTitle: "Lataa",
                exportCSVName: "Lataa CSV",
                exportPDFName: "Lataa PDF",
              },
              body: {
                emptyDataSourceMessage: "Ladataan laitteita...",
                filterRow: {
                  filterTooltip: "Suodatus",
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <FilterDialog
        open={openFilterDialog}
        columns={columns}
        onClose={handleCloseFilterDialog}
      />
      <AddDevice token={token} open={open} onClose={handleClose} />
    </>
  );
}
