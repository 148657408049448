import axios from 'axios';

import { config } from '../../Constants';

const basePath = `/db`;
const entosPath = '/entos';

export default function getApi(token){
  return axios.create({
    baseURL: `${config.url.MYSQL_DB}${basePath}`,
    headers: {"Authorization" : `Bearer ${token}`}
  });
};

export const getEntosApi = (token) => {
  return axios.create({
    baseURL: `${config.url.MYSQL_DB}${entosPath}`,
    headers: {"Authorization" : `Bearer ${token}`}
  });
};

export const audience = `https://iot.cervi.fi/db`;