import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import renameOrganisation from '../../../../services/db/renameOrganisation';
import DeleteOutlined from "@material-ui/icons/DeleteOutlined"
import deleteCustomerOrganisation from "../../../../services/db/delOrg";

export default function UpdateCustomer(props) {

  const { onClose, open, token, customer, onError} = props;
  const { org_id, org_name } = customer;
  const [ name, setName ] = useState();

  useEffect(() => {
    if(org_name) {
      setName(org_name);
    }
  }, [org_name]); 

  const deleteCustomer = () => {   
    deleteCustomerOrganisation(token, org_id)
      .then(onClose())
      .catch((err) => {
       if (err.error && err.error)  {
         handelError(err.error);
       } 
    })
  }

  const updateCustomer = () => {
    if (!name) {
      onClose();
      return
    }    
    renameOrganisation(token, org_id, name)
      .then(onClose())
      .catch((err) => {
        // Todo add error dialog
        console.log("Error", err);
      })
  };

  const handelError = (error) => {
    if (error.code === "23503"){
      onError();
    } else {
      console.log("Error deleting organisation:", error);
    }  
  } 

  return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Asiakkaan muokkaus</DialogTitle>
        <DialogContent>
          <DialogContentText>
             Muuta asiakkaan tiedot
          </DialogContentText>
          <TextField 
            autoFocus
            margin="dense"
            label="Uusi organisaatio nimi"
            fullWidth
            defaultValue={name}
            onChange={(event) => setName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteCustomer} color="secondary" startIcon={<DeleteOutlined />} >
            Poista asiakas kokonaan
          </Button>
          <Button onClick={onClose} color="primary">   
            Peruuta
          </Button>
          <Button variant="contained" onClick={updateCustomer} color="primary">
            Muokkaa
          </Button>
        </DialogActions>
      </Dialog>
  );
}