import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import { epochFormat } from "../../components/Header/components/useFilter";

export default function useSites(token, orgId, filter) {
  const [sites, setSites] = useState(false);
  const [error, setError] = useState(false);

  const update = useCallback(() => {
    if (token) {
      getSites(token, orgId, filter)
        .then(res => {
          setSites(res);
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [token, orgId, filter]);

  useEffect(() => {
    update();
  },[update, token, orgId, filter]);

  return { sites, update, error };
}

export function getSites(token, orgId, filter) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    const { startDate, endDate } = epochFormat(filter);
    api
      .get("/sites", {
        params: {
          orgId,
          filter_start_date: startDate,
          filter_end_date: endDate,
        },
      })
      .then(res => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
