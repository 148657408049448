import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";


export default function useToken(audience) {

  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const [token, setToken] = useState(false);
  const [error, setError] = useState(false);  

  useEffect(() => {
    const getAccessToken = async () => {   
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently({
          audience: audience,
        });
        setToken(accessToken);
      } catch (err) {
        if (err.error === 'consent_required' || err.error === 'login_required') {
          accessToken = await getAccessTokenWithPopup({ audience: audience });  
          setToken(accessToken);
        } else {
          console.error('get token error', err);
          setError(err);
        }
      }
    };
    getAccessToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return {token, error}
}  


