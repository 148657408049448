import getApi from "./api";

export default function addSite(token, name, location, org, user, tags) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api.get('/addSite', {
      params: {
        site: {
          name, 
          location, 
          org: {id: org.org_id, name: org.org_name}, 
          user: {id: user.id, name: user.fullname }, 
          tags
        }
      }
    })
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  })
}

