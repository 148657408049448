import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import useStyles from "./styles";

export default function PressGuardContent(props) {
  const { info } = props;
  const classes = useStyles();

  return (
    <>
      { info ?
        Object.entries(info).map(([key, entry]) => 
        entry===null?null: <DialogContentText className={classes.details} key={key} >{`${key}: ${entry}`}</DialogContentText>)
      : null}  
    </>
  )
}