import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  linkButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: 'pointer'
  }
}));
