import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import moment from "moment";

export default function LowFanSpeed(props) {
  const { incident, data, options } = props;
  const { height, format } = options;
  const { hAxis, textTime } = format;

  const startTime = incident.startTime * 1000;
  const endTime = incident.endTime * 1000;

  const [chartData, setChartData] = useState(false);

  const parseToInt = val => {
    return parseInt(val) || 0;
  };

  useEffect(() => {
    const dataArray = [
      [
        { type: "date" },
        { type: "number", label: "Alaraja" },
        { type: "number", label: "Suurin puhallinnopeus kierroslkm" },
        { type: "number", label: "Pienin puhallinnopeus kierroslkm" },
        { type: "number", label: "Nopeus, kierroslkm" },
      ],
    ];
    data.forEach(row => {
      dataArray.push([
        new Date(row.time * 1000),
        parseToInt(row.rpm_lower_limit),
        parseToInt(row.motor_speed_max),
        parseToInt(row.motor_speed_min),
        parseToInt(row.motor_speed),
        
      ]);
    });
    setChartData(dataArray);
  }, [data]);

  return (
    <>
      <Chart
        height={height}
        chartType="LineChart"
        data={chartData}
        options={{
          hAxis: {
            title: `Aikajakso: ${moment(startTime).format(textTime)} - ${moment(
              endTime,
            ).format(textTime)}`,
            format: hAxis,
            minValue: new Date(startTime),
            maxValue: new Date(endTime),
            gridlines: { color: "none" },
          },
          series: {
            0: options.rangeSettings,
            1: options.maxMin1Settings,
            2: options.maxMin1Settings,
            3: options.line1Settings,
          },
          vAxis: {
            title: "Kierroksia minuutissa",
          },
        }}
      />
    </>
  );
}
