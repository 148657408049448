import { useState, useEffect, useCallback  } from "react";
import getApi from "./api";

export default function useUsers(token) {
  const [users, setUsers] = useState(false);
  const [error, setError] = useState(false);
  
  const update = useCallback(() => {
    if (token) {
      getUsers(token)
        .then((res) => {
          setUsers(res);
        })
        .catch((err) => {
          setError(err);
        })
    }
  }, [token])
  useEffect(() => {
    update();

  }, [update, token]);
  return { users, update, error } 
}  

export function getUsers(token) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api.get('/users')
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  })
}