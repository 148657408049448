import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import useStyles from "./styles";
import moment from "moment";
import useEntosInfo from '../../../../../../../services/db/useEntosInfo';
import useToken from '../../../../../../../services/useToken';
import { audience } from '../../../../../../../services/db/api';

export default function TopairContent(props) {
  const classes = useStyles();

  let { info } = props;
  const { token } = useToken(audience);
  const {info: deviceInfo, error} = useEntosInfo(token, info.token);

  if (error) {
    console.error('Get info error', error);
  }
  
  if (deviceInfo && deviceInfo.metadata) {
     info = {...info, ...deviceInfo.metadata};
  }
  return (
    <>
      { info ?
        Object.entries(info).map(([key, entry]) => {
          if (key === 'Phone_number') { return null; }
          if (key === 'Site') { return null; }
          if (key === 'device_id') { return null; }
          if (key === 'id') { return null; }
          if (key === 'MAC') { return null; }
          if (key === 'Customer') { return null; }
          if (key === 'Ip address') {
            return (<DialogContentText className={classes.details} key={key} >{`Haetaan tällä hetkellä IP-osoiteesta (vaihtuva osoite): ${entry !== null ? entry : ""}`}</DialogContentText>)
          }
          if (key === 'polled') {
            return (<DialogContentText className={classes.details} key={key} >{`Peräkkäiset epäonnistuneet yritykset: ${entry !== null ? entry : ""}`}</DialogContentText>)
          }
          if (key === 'first_measurement_timestamp') {
            return (<DialogContentText className={classes.details} key={key} >{`Ensimmäinen mittaus on kirjattu: ${entry !== null ? moment(entry *1000).fromNow() : ""}`}</DialogContentText>)
          }
          if (key === 'last_measurement_timestamp') {
            return (<DialogContentText className={classes.details} key={key} >{`Edellinen mittaus on kirjattu: ${entry !== null ? moment(entry *1000).fromNow() : ""}`}</DialogContentText>)
          }
          if (key === 'Phone number') {
            return (<DialogContentText className={classes.details} key={key} >{`SIM-kortin numero: ${entry !== null ? entry : ""}`}</DialogContentText>)
          }
          if (key === 'state') {
            let str_state;
            switch (entry ) {
              case undefined:
                str_state='';
                break;
              case 'wait for SMS':
                str_state = 'Olen lähettänyt SMS (vaikuttaa siltä, että IP osoite vaihtunut), odotan tekstiviesti-vastausta';
                break;
              case 'wait for initial CGI':
                str_state = 'Olen lähettänyt SMS (tarvitsen laitteen IP-osoitetta), odotan tekstiviesti-vastausta';
                break;
              case 'normal CGI poling':
                str_state = 'Normaali tiedonhaku käynnissä, nykyinen IP-osoite vaikuttaa toimivan';
                break;
              case 'retry CGI polling':
                str_state = 'Ongelmia tiedonhaussa, kokeilen vielä uudelleen ennen kun lähetän tekstiviestiä.';
                break;
                default:
                str_state = entry;
                break;
                      
            }
            return (<DialogContentText className={classes.details} key={key} >{`Nykytila: ${str_state}`}</DialogContentText>)
          }
          if (key === 'updated') {
            return (<DialogContentText className={classes.details} key={key} >{`Päivitetty: ${entry !== null ? moment(entry).fromNow() : ""}`}</DialogContentText>)

          }
          if (entry === null) { return null };
          return (<DialogContentText className={classes.details} key={key} >{`${key}: ${entry !== null ? entry : ""}`}</DialogContentText>)
        })
        : null}
    </>
  )
}