import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import moment from "moment";

export default function DeadSensorRH(props) {
  const { incident, data, options } = props;
  const { height, format } = options;
  const { hAxis, textTime } = format;

  const startTime = incident.startTime * 1000;
  const endTime = incident.endTime * 1000;

  const [chartData, setChartData] = useState(false);

  const parseToInt = val => {
    return parseInt(val) || 0;
  };

  useEffect(() => {
    if (data.length === 0) return;

    const dataArray = [
      [
        { type: "date" },
        { type: "number", label: "Ilmankosteuden yläraja (%)" },
        { type: "number", label: "Suurin puhallinnopeus kierroslkm" },
        { type: "number", label: "Pienin puhallinnopeus kierroslkm" },
        { type: "number", label: "Suurin ilmankosteus (%)" },
        { type: "number", label: "Pienin ilmankosteus (%)" },
        { type: "number", label: "Puhallinnopeus, kierroslkm" },
        { type: "number", label: "Ilman suhteellinen kosteus (%)" },
      ],
    ];
    data.forEach(row => {
      dataArray.push([
        new Date(row.time * 1000),
        parseToInt(row.rh_upper_limit),
        parseToInt(row.motor_speed_max),
        parseToInt(row.motor_speed_min),
        parseToInt(row.rh_max),
        parseToInt(row.rh_min),
        parseToInt(row.motor_speed),
        parseToInt(row.rh),
      ]);
      console.log("pushing row ", row);
    });

    setChartData(dataArray);
  }, [data]);

  return (
    <>
      {chartData ? (
        <Chart
          height={height}
          chartType="LineChart"
          data={chartData}
          options={{
            hAxis: {
              title: `Aikajakso: ${moment(startTime).format(
                textTime,
              )} - ${moment(endTime).format(textTime)}`,
              format: hAxis,
              minValue: new Date(startTime),
              maxValue: new Date(endTime),
              gridlines: { color: "none" },
            },
            series: {
              0: { targetAxisIndex: 0, ...options.rangeSettings},
              1: { targetAxisIndex: 1, ...options.maxMin2Settings},
              2: { targetAxisIndex: 1, ...options.maxMin2Settings},
              3: { targetAxisIndex: 0, ...options.maxMin1Settings},
              4: { targetAxisIndex: 0, ...options.maxMin1Settings},
              5: { targetAxisIndex: 1, ...options.line2Settings},
              6: { targetAxisIndex: 0, ...options.line1Settings},
            },
            vAxis: {
              // TODO fix this dos not work for the moment 
              // Adds titles to each axis.
              0: { title: "Ilmankosteus %" },
              1: { title: "Kierroksia minuutissa" },
            },
          }}
        />
      ) : (
        <div>
          Ei mittaustietoa ajalle: {moment(startTime).format(textTime)} -{" "}
          {moment(endTime).format(textTime)}
        </div>
      )}
    </>
  );
}
