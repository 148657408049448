import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";

import InfoIcon from "@material-ui/icons/Info";
import Devices from "./components/devices";
import Tags from "./components/tags";

import AttachDeviceToSite from "../../../../services/db/attachDeviceToSite";
import PressGuard from "../sigfox/pressGuard/PressGuard";
import { deviceTypes } from "../../../../Constants";

const {
  TopAir,
  SF_MultiGuard_R2,
  SF_PressGuard_R4_1,
  SF_Ranger_1_3,
  SF_TempGuard_R3,
} = deviceTypes;

export default function AttachDevice(props) {
  const { onClose, open, siteId, tags, token } = props;

  const [device, setDevice] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [settings, setSettings] = useState();
  const [description, setDescription] = useState();

  const addSiteTag = tag => {
    const list = [...selectedTags];
    list.push(tag.site_tag_id);
    setSelectedTags(list);
  };

  const removeSiteTag = tag => {
    const list = [...selectedTags];
    const index = list.indexOf(tag.site_tag_id);
    if (index > -1) {
      list.splice(index, 1);
    }
    setSelectedTags(list);
  };

  const submitSite = () => {
    AttachDeviceToSite(
      token,
      siteId,
      { device_id: device.device_id, type_id: device.type_id },
      selectedTags,
      description,
      settings,
    )
      .then(() => {
        closeAttachDialog(true);
      })
      .catch(err => {
        // Todo add error dialog
        console.log("Error", err);
      });
  };

  const closeAttachDialog = attached => {
    setSelectedTags([]);
    setSettings(undefined);
    setDescription(undefined);
    setDevice(undefined);
    onClose(attached);
  };

  const drawSettings = () => {
    switch (parseInt(device.type_id)) {
      case TopAir:
        return null;
      case SF_MultiGuard_R2:
        return null;
      case SF_PressGuard_R4_1:
        return <PressGuard update={setSettings} />;
      case SF_Ranger_1_3:
        return null;
      case SF_TempGuard_R3:
        return null;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => closeAttachDialog(false)}
      maxWidth={"sm"}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={submitSite}>
        <DialogTitle id="form-dialog-title">Liitä laite</DialogTitle>
        <DialogContent>
          {/** plot the available site tags (selectable), and plot a dropdown for selecing the desired device */}
          <Devices token={token} add={setDevice} />
          {device ? drawSettings() : null}
          <DialogContentText>
            Kohdista mittaustiedot tageilla:
          </DialogContentText>
          <Tags tags={tags} add={addSiteTag} remove={removeSiteTag} />

          <TextField
            label="Lisätietoa"
            helperText="Kuvaa mihin tämä laite on asennettu, ja mitä se mittaa."
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InfoIcon />
                </InputAdornment>
              ),
            }}
            onChange={event => setDescription(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeAttachDialog(false)} color="primary">
            Peruuta
          </Button>
          <Button variant="contained" type="submit" color="primary">
            Liitä
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
