import getApi from "./api";

export default function editUser(token, user) {
  // {id, email, enabled, fullname, insightly_id, username}
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api.get('/editUser', {
      params: {
        user
      }
    })
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  })
}