import { useState, useEffect  } from "react";
import getApi from "./api";

export default function useTagTypes(token) {
  const [tags, setTags] = useState(false);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    if (token) {
      getTagTypes(token)
        .then((res) => {
          setTags(res);
        })
        .catch((err) => {
          setError(err);
        })
    }
  }, [token])
  
  return { tags, error } 
}  

export function getTagTypes(token) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api.get('/tagTypes')
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  })
}