import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  tagTypeContentText: {
    marginBottom: 1
  },
  tagTypeContent: {
    paddingTop: 0
  },
  pressGuardInput: {
    width: 150
  }
}));
