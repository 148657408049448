import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import moment from 'moment';


export default function Pressure(props) {


  const { incident, data , options} = props;
  const { height, format } = options;
  const { hAxis, textTime } = format;

  const startTime = incident.startTime * 1000;
  const endTime = incident.endTime * 1000; 

  const [ chartData, setChartData ] = useState(false);  

  useEffect(() => {  
    const dataArray = [[
      { type: 'date'},
      { type: 'number', label: 'Ilmanpaine' },
      { type: 'number', label: 'Alaraja' },
      { type: 'number', label: 'Yläraja' }
    ]]
    data.forEach((row) => {
      dataArray.push([new Date(row.time * 1000), row.pressure_difference, row.lower_limit, row.upper_limit])
    });
    setChartData(dataArray);

  }, [data]);  

  return (
    <>
      <Chart
        height={height}
        chartType="LineChart"
        data={ chartData }
        options={{ 
          hAxis: {
            title:  `Aikajakso: ${moment(startTime).format(textTime)} - ${moment(endTime).format(textTime)}`,
            format: hAxis,
            gridlines: {color: 'none'}
          },
          vAxis: { 
            title: 'Pa'
          }
        }}
      />
    </>
  )
}


