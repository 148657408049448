import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  colorBox: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1.5)
  },
  box: {
    display: 'flex'
  },
  prio1: {
    backgroundColor: theme.palette.incidents.prio1.main,
  },
  prio2: {
    backgroundColor: theme.palette.incidents.prio2.main,
  },
  inMonitoring: {
    backgroundColor: theme.palette.incidents.inMonitor.main,
  },
}));