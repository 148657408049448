import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import moment from "moment";

export default function DeadSensorTemp(props) {
  const { incident, data, options } = props;
  const { height, format } = options;
  const { hAxis, textTime } = format;

  const startTime = incident.startTime * 1000;
  const endTime = incident.endTime * 1000;

  const [chartData, setChartData] = useState(false);

  const parseToInt = val => {
    return parseInt(val) || 0;
  };

  useEffect(() => {
    if (data && incident) {
      let label;
      let min;
      let max;
      switch (incident.incidentType) {
        case "dead_exchaust_sensor":
          label = "Jäteilman lämpötila";
          min = "Jäteilman alin lämpötila";
          max = "Jäteilman ylin lämpötila";
          break;
        case "dead_outdoor_sensor":
          label = "Ulkolämpötila";
          min = "Alin ulkolämpötila";
          max = "Ylin ulkolämpötila";
          break;
        default:
          break;
      }

      const dataArray = [
        [
          { type: "date" },
          { type: "number", label: max },
          { type: "number", label: min },
          { type: "number", label: label },
        ],
      ];
      data.forEach(row => {
        dataArray.push([
          new Date(row.time * 1000),
          parseToInt(row.temperature_max),
          parseToInt(row.temperature_min),
          parseToInt(row.temperature_avg),
        ]);
      });
      setChartData(dataArray);
    }
  }, [data, incident]);

  return (
    <>
      <Chart
        height={height}
        chartType="LineChart"
        data={chartData}
        options={{
          hAxis: {
            title: `Aikajakso: ${moment(startTime).format(textTime)} - ${moment(
              endTime,
            ).format(textTime)}`,
            format: hAxis,
            minValue: new Date(startTime),
            maxValue: new Date(endTime),
            gridlines: { color: "none" },
          },
          // Gives each series an axis that matches the vAxes number below.
          series: {
            0: options.maxMin1Settings,
            1: options.maxMin1Settings,
            2: options.line1Settings,
          },
        }}
      />
    </>
  );
}
