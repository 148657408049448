import getApi from "./api";

export function getSearchInfo(token, siteId) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api
      .get("/searchInfo", {
        params: {
          siteId
        }
      })
      .then(res => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data[0]);
      })
      .catch(err => {
        reject(err);
      });
  });
}
