import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PressGuard from "../../../sigfox/pressGuard/PressGuard";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import setIncidentTypeVisible from "../../../../../../services/db/setIncidentTypeVisible";
import { updateSettings } from "../../../../../../services/db/updateSettings";
import { deviceTypes } from "../../../../../../Constants";

// styles
import useStyles from "./styles";

const {
  TopAir,
  SF_MultiGuard_R2,
  SF_PressGuard_R4_1,
  SF_Ranger_1_3,
  SF_TempGuard_R3,
} = deviceTypes;

export default function SettingsDialog(props) {
  const classes = useStyles();

  const { token, onClose, open, deviceInfo } = props;
  const { device_id, name, alert_states } = deviceInfo[0];
  const type_id = parseInt(deviceInfo[0].type_id);

  const [title, setTitle] = useState();
  const [checked, setChecked] = useState([]);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    if (!deviceInfo) {
      return;
    }

    const sfDevice = () => {
      setTitle(`SigFox ${name}, tunniste: ${deviceInfo[1].sf_device_id}`);
    };

    switch (type_id) {
      case TopAir:
        setTitle(`TopAir, ${deviceInfo[1].mac}`);
        break;
      case SF_MultiGuard_R2:
        sfDevice();
        break;
      case SF_PressGuard_R4_1:
        sfDevice();
        break;
      case SF_Ranger_1_3:
        sfDevice();
        break;
      case SF_TempGuard_R3:
        sfDevice();
        break;
      default:
        break;
    }
  }, [deviceInfo, name, type_id]);

  const drawSettings = () => {
    switch (type_id) {
      case TopAir:
        return null;
      case SF_MultiGuard_R2:
        return null;
      case SF_PressGuard_R4_1:
        return <PressGuard settings={deviceInfo[2]} update={setSettings} />;
      case SF_Ranger_1_3:
        return null;
      case SF_TempGuard_R3:
        return null;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (alert_states) {
      const newChecked = [];
      alert_states.forEach(element => {
        if (element.hidden === "VISIBLE" ) newChecked.push(element);
      });
      setChecked(newChecked);
    }
  }, [alert_states]);

  const handleToggle = element => () => {
    const currentIndex = checked.indexOf(element);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(element);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handelUpdate = async () => {
    const visibleStates = checked.map(state => state.incident_type_id);

    setIncidentTypeVisible(token, device_id, visibleStates)
      .then(() => {
        updateSettings(token, device_id, type_id, settings)
          .then(onClose(true))
          .catch((err) => {
            console.log("Update settings error", err);
          })      
      })
      .catch((err) => {
        console.log("set incident type visible error", err);
      });
  };

  const getState = state => {
    switch (state) {
      case "NORMAL":
        return "Normaalitilassa";
      case "ACTIVE_ALERT":
        return "Vikatilanne päällä juuri nyt";
      case "RECENT_ALERT":
        return "Oli äskettäin vikatilassa, saattaa vielä palautua sinne";
      default:
        console.log("Unknown alert state", state);
        return null;
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth={"sm"}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <List dense={true}>
          {alert_states.map((element, index) => (
            element.incident_type_id < 0) ? null: (
            <ListItem
              className={classes.list}
              key={index}
              onClick={handleToggle(element)}
            >
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  checked={checked.indexOf(element) !== -1}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${element.name}`}
                secondary={getState(element.state)}
              />
            </ListItem>
            )
          )}
        </List>
        {deviceInfo && deviceInfo[2] ? drawSettings() : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Peruuta
        </Button>
        <Button variant="contained" onClick={handelUpdate} color="primary">
          Päivitä
        </Button>
      </DialogActions>
    </Dialog>
  );
}
