import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FilterIcon from "@material-ui/icons/FilterListOutlined";

// components
import TagChip from "../../../../components/TagChip/TagChip";

// styles
import useStyles from "./styles";

export default function SiteTags(props) {
  const { tags, onUpdateFilter } = props;
  const classes = useStyles();

  const [siteTags, setSiteTags] = useState();

  useEffect(() => {
    if (!tags) {
      return;
    }
    const tagList = {};
    for (const tag of tags) {
      if (!tagList[tag.type_id]) {
        tagList[tag.type_id] = [];
      }
      tag["selected"] = false;
      tagList[tag.type_id].push(tag);
    }
    setSiteTags(tagList);
  }, [tags]);

  const renderTags = (id, tags, index) => {
    const getChips = () => {
      return (
        <Grid item xs={9} className={classes.tagChip}>
          {tags.map((tag, index) => (
            <TagChip
              onClick={() => {
                let list = { ...siteTags };
                let items = [...list[id]];
                let item = { ...items[index] };
                item.selected = tag.selected ? false : true;
                items[index] = item;
                list[id] = items;
                setSiteTags(list);
              }}
              clickable
              color={tag.selected ? "primary" : "default"}
              {...tag}
              key={index}
            />
          ))}
        </Grid>
      );
    };

    const getLabel = () => {
      switch (id) {
        case "1":
          return (
            <Grid item xs={3} className={classes.tagName}>
              Rakennukset
            </Grid>
          );
        case "2":
          return (
            <Grid item xs={3} className={classes.tagName}>
              Portaat
            </Grid>
          );
        case "3":
          return (
            <Grid item xs={3} className={classes.tagName}>
              Kerrokset
            </Grid>
          );
        case "4":
          return (
            <Grid item xs={3} className={classes.tagName}>
              Tilat
            </Grid>
          );
        case "5":
          return (
            <Grid item xs={3} className={classes.tagName}>
              Konetunnukset
            </Grid>
          );
        case "6":
          return (
            <Grid item xs={3} className={classes.tagName}>
              Huoneistot
            </Grid>
          );
        default:
          return (
            <Grid item xs={3} className={classes.tagName}>
              {id}
            </Grid>
          );
      }
    };

    return (
      <React.Fragment key={index}>
        <Grid item xs={12} sm={6} xl={3}>
          <Grid container spacing={4}>
            {getLabel()}
            {getChips()}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const updateFilter = () => {
    let newFilter = {};
    for (let [key, value] of Object.entries(siteTags)) {
      for (const entry of value) {
        if (entry.selected) {
          if (!newFilter[key]) {
            newFilter[key] = [];
          }
          newFilter[key].push(entry.site_tag_id);
        }
      }
    }
    onUpdateFilter(newFilter);
  };

  if (!siteTags) {
    return null;
  }
  return (
    <Grid item xs={12} md={12} xl={12} justifycontent="center">
      <h4>Näkymän suodattimet</h4>
      <Grid container display="flex" direction="row">
        {Object.entries(siteTags).map(([key, value], index) =>
          renderTags(key, value, index),
        )}
      </Grid>
      <Button
        variant="outlined"
        size="small"
        onClick={updateFilter}
        className={classes.filterButton}
        startIcon={<FilterIcon />}
      >
        Päivitä suodatus
      </Button>
      <Divider />
    </Grid>
  );
}
