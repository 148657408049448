import getApi from "./api";

export default function delDeviceRelationToSite(token, device_id) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api.get('/delDeviceRelationToSite', {
      params: {
        device_id
      }
    })
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  })
}

