import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  details: {
    fontSize: "0.55rem",
    spacing: "0px",
    padding: "0px",
    margin: "0px",
  }
}));
