import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import PublicIcon from "@material-ui/icons/Public";
//import PressGuard from '../attachDevice/components/pressGuard';
import useStyles from "./styles";
import PressGuardContent from "./components/pressGuardContent";
import TopairContent from "./components/topairContent";
import SenserContent from "./components/senserContent";
import { deviceTypes } from "../../../../../../Constants";

import moment from "moment";

const {
  TopAir,
  SF_MultiGuard_R2,
  SF_PressGuard_R4_1,
  SF_Ranger_1_3,
  SF_TempGuard_R3,
  Senser,
} = deviceTypes;

export default function DeviceInfoDialog(props) {
  const classes = useStyles();
  const { onClose, open, deviceInfo } = props;
  const [url, setUrl] = useState();
  const [title, setTitle] = useState();
  const [created, setCreated] = useState();

  const type_id = parseInt(deviceInfo[0].type_id);

  useEffect(() => {
    if (!deviceInfo) {
      return;
    }
    const { created, name } = deviceInfo[0];
    // TODO format time
    setCreated(created);
    // TODO setSettings from deviceInfo[2]
    const sfDevice = () => {
      setTitle(`SigFox ${name}, tunniste: ${deviceInfo[1].sf_device_id}`);
      setUrl(`https://fi.foxeriot.com/#/devices/00${deviceInfo[1].sf_device_id}`)
    };

    switch (type_id) {
      case TopAir:
        setTitle(`TopAir, ${deviceInfo[1].mac}`);
        if (deviceInfo[1]['ip_address']){
          setUrl(`http://${deviceInfo[1]['ip_address']}/`);
        }
        break;
      case SF_MultiGuard_R2:    
      case SF_PressGuard_R4_1: 
      case SF_Ranger_1_3:    
      case SF_TempGuard_R3:
        sfDevice();
        break;
      case Senser:
        setTitle(`Senser, ${deviceInfo[1].imei}`);
        if (deviceInfo[1].token){
          setUrl(`https://www.valvonnassa.fi/device/wmu5?token=${deviceInfo[1].token}`)
        }
        break;  
      default:
        break;
    }
  }, [deviceInfo, type_id]);

  const submitSite = () => {
    // TODO update device in backend if changed
    // console.log("Submit device", device);
    /*  AttachDeviceToSite(token, siteId, {device_id: device.device_id, type_id: device.type_id} , selectedTags, description, settings)
      .then(() => {
        closeAttachDialog(true);
      })
      .catch((err) => {
        // Todo add error dialog
        console.log("Error", err);
      }); */
  };

  const closeAttachDialog = attached => {
    onClose(attached);
  };

  const drawTypeSpecificContent = () => {
    switch (type_id) {
      case TopAir:
        return <TopairContent info={deviceInfo[1]} />;
      case SF_MultiGuard_R2:
      case SF_PressGuard_R4_1:
      case SF_Ranger_1_3:    
      case SF_TempGuard_R3:
        return <PressGuardContent info={deviceInfo[1]} />;
      case Senser:
        return <SenserContent info={deviceInfo[1]} />  
      default:
        return Object.entries(deviceInfo[1]).map(([key, entry]) => (
          <DialogContentText key={key}>{`${key}: ${
            entry !== null ? entry : ""
          }`}</DialogContentText>
        ));
    }
  };

  const drawSettings = () => {
    switch (type_id) {
      case TopAir:
        return null;
      case SF_MultiGuard_R2:
        return null;
      case SF_PressGuard_R4_1:
        return null;
      /*return (
          <PressGuard settings={deviceInfo[2]} />
        );*/
      case SF_Ranger_1_3:
        return null;
      case SF_TempGuard_R3:
        return null;
      default:
        return null;
    }
  };
  // console.log("UpdateDevice device", deviceInfo);
  return (
    <Dialog
      open={open}
      onClose={() => closeAttachDialog(false)}
      maxWidth={"sm"}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={submitSite}>
        <DialogTitle>
          {title}
          {url ? (
            <Tooltip title={url}>
              <PublicIcon
                onClick={() => window.open(url, `_blank`)}
                className={classes.linkButton}
              />
            </Tooltip>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{`Lisätty ${moment(created * 1000).format(
            "ddd D.M.YYYY",
          )}`}</DialogContentText>
          {deviceInfo && deviceInfo[1] ? drawTypeSpecificContent() : null}
          {deviceInfo && deviceInfo[2] ? drawSettings() : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeAttachDialog(false)} color="primary">
            Sulje
          </Button>
          {/** 
          <Button type="submit" color="primary">
            Liitä
          </Button>*/}
        </DialogActions>
      </form>
    </Dialog>
  );
}
