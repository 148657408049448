import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import InputAdornment from '@material-ui/core/InputAdornment';

import useNonAttachedDevices from '../../../../../services/db/useNonAttachedDevices';
import FanIcon from '../../../../../icons/FanIcon';

export default function Devices(props) {

  const { add, token } = props;
  
  const { devices } = useNonAttachedDevices(token);

  const [ device, setDevice ] = useState(null);
  
  useEffect(() => {
    add(device);
  }, [add, device]);
  
  return (
    <Autocomplete
      value={device}
      options={devices ? devices : []}
      groupBy={(option) => option.type_name}
      getOptionLabel={(option) => option.device_details}
      onChange={(event, newDevice) => { 
        setDevice(newDevice);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Laite / tietolähde"
          margin="normal"
          required
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
              <InputAdornment position="start">
                <FanIcon />
              </InputAdornment>
              
              </>
            ),
          }}
        />
      )}
  />
  );
}