import { useState, useEffect  } from "react";
import getApi from "./api";

export default function useDeviceTypes(token, deviceId) {
  const [deviceTypes, setDeviceTypes] = useState(false);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    update();
  }, [token])

  const update = () => {
    if (token) {
      getDeviceTypes(token, deviceId)
      .then((res) => {
        setDeviceTypes(res);
      })
      .catch((err) => {
        setError(err);
      })
    }
  }
   
  return { deviceTypes, update, error } 
}  

export function getDeviceTypes(token, deviceId) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api.get('/deviceTypes', {
      params: {
        deviceId
      }
    })
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  })
}