import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chart from "../../../IncidentCard/components/chart/Chart";
import { useForm } from "react-hook-form";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CommentIncident from "../../../../../../services/db/commentIncident";
import CommentAndCloseIncident from "../../../../../../services/db/commentAndCloseIncident";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";

// styles
import useStyles from "./styles";

export default function IncidentDialog(props) {
  const classes = useStyles();

  const { user } = useAuth0();
  const { token, open, incident, comments, onClose } = props;
  const { incident_id, closing_comment_id, incident_type_name } = incident;

  const { register, handleSubmit, errors } = useForm();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(true);
  }, [errors]);

  const onSubmitCloseAll = handleSubmit(data => {
    CommentAndCloseIncident(
      token,
      incident_id,
      user.nickname ? user.nickname : user.name,
      data.comment,
      true,
    ).then(() => {
      onClose(true, true);
    });
  });

  const onSubmitClose = handleSubmit(data => {
    CommentAndCloseIncident(
      token,
      incident_id,
      user.nickname ? user.nickname : user.name,
      data.comment,
      false,
    ).then(() => {
      onClose(true, true);
    });
  });

  const onSubmitComment = handleSubmit(data => {
    CommentIncident(
      token,
      incident_id,
      user.nickname ? user.nickname : user.name,
      data.comment,
    ).then(() => {
      onClose(true);
    });
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{incident_type_name}</DialogTitle>
        <DialogContent>
          {incident_id ? (
            <Chart token={token} incident_id={incident_id} />
          ) : null}

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                name="comment"
                error={errors.comment ? true : false}
                inputRef={register({ required: "Kirjoita kommentti ensin!" })}
                label="Kommentoi vikatilanteen syyt ja mahdolliset toimenpiteet"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              {comments ? (
                <div className={classes.listDiv}>
                  <List dense={true} className={classes.list}>
                    {comments.map((comment, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={`${moment(
                            comment.commented_at * 1000,
                          ).format("DD.MM.YYYY")}: ${comment.comment}`}
                          secondary={comment.author}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {!closing_comment_id ? (
            <>
              <Button onClick={onSubmitCloseAll} color="primary">
                Kuittaa tämä ja edelliset
              </Button>
              <div style={{ flex: "1 0 0" }} />
              <Button onClick={onSubmitClose} color="primary">
                Kuittaa vain tämä
              </Button>
            </>
          ) : null}
          <Button onClick={onSubmitComment} color="primary">
            {!closing_comment_id ? (
              <>Kommentoi, jätä kuittaamatta </>
            ) : (
              <>Jätä kommentti </>
            )}
          </Button>
          <Button variant="contained" onClick={onClose} color="primary">
            Sulje
          </Button>
        </DialogActions>
      </Dialog>

      {Object.keys(errors).length > 0 ? (
        <Snackbar open={showError}>
          <Alert severity="error">{Object.values(errors)[0].message}</Alert>
        </Snackbar>
      ) : null}
    </>
  );
}
