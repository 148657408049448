const prod = {
  url: {
    MYSQL_DB: `https://backend-t6tsmoleva-ew.a.run.app`,
  }
 };

const dev = {
  url: {
    MYSQL_DB: `http://localhost:8181`,
  }
 };

export const config = process.env.NODE_ENV === 'development' ? dev : prod;

//Compare to db device_type
export const deviceTypes = {
  TopAir: 1,
  SF_MultiGuard_R2: 4,
  SF_PressGuard_R4_1: 2,
  SF_Ranger_1_3: 5,
  SF_TempGuard_R3: 3,
  Senser: 6,
}