import React from "react";
import BuildingIcon from '@material-ui/icons/Apartment'
import StaircaseIcon from '@material-ui/icons/TransferWithinAStation';
import FloorIcon from '@material-ui/icons/ClearAll';
import DoorIcon from '@material-ui/icons/MeetingRoom'
import Chip from '@material-ui/core/Chip';

// styles
import useStyles from "./styles";

export default function TagChip(props) {

  const classes = useStyles();

  const { type_id, name} = props;
  
  switch (type_id) {
    case "1":
      return <Chip className={classes.chip} size="small" icon={<BuildingIcon/>} { ...props } label={name} /> // Rakennus
    case "2":
      return <Chip className={classes.chip} size="small" icon={<StaircaseIcon/>} { ...props } label={name} /> // Porras
    case "3":
      return <Chip className={classes.chip} size="small" icon={<FloorIcon/>} { ...props } label={name} /> // Kerros
    case "4":
      return <Chip className={classes.chip} size="small" icon={<DoorIcon/>} { ...props } label={name} /> // Tila
    case "5":
      return <Chip className={classes.chip} size="small" icon={<DoorIcon/>} { ...props } label={name} /> // Konetunnus
    case "6":
      return <Chip className={classes.chip} size="small" icon={<DoorIcon/>} { ...props } label={name} /> // Huoneisto
    default:
      return <Chip className={classes.chip} size="small" icon={<DoorIcon/>} { ...props } label={name} /> 
  }
}






