import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import { useFilter } from "../../../../../components/Header/components/useFilter";

import useCustomers from "../../../../../services/db/useCustomers";

const filter = createFilterOptions();

export default function Customer(props) {
  const { add, token, org } = props;

  const dateFilter = useFilter();
  const { customers } = useCustomers(token, dateFilter);

  const [customer, setCustomer] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (org) {
      setCustomer({ org_id: org.id, org_name: org.name });
      setDisabled(true);
    }
  }, [org]);

  useEffect(() => {
    add(customer);
  }, [add, customer]);

  return (
    <Autocomplete
      value={customer}
      options={customers ? customers : []}
      onChange={(event, newCustomer) => {
        if (typeof newCustomer === "string") {
          setCustomer({
            org_name: newCustomer,
          });
        } else if (newCustomer && newCustomer.inputValue) {
          // Create a new customer
          setCustomer({
            org_name: newCustomer.inputValue,
          });
        } else {
          setCustomer(newCustomer);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            org_name: `LISÄÄ "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.org_name;
      }}
      renderOption={option => option.org_name}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="Customer"
      disabled={disabled}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Asiakasyritys"
          helperText="Tulee hakea vaihtoehtoja Insightlystä + omasta tietokannasta, tai luo uuden yrityksen"
          margin="normal"
          required
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <PeopleOutlineIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    />
  );
}
