import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import localization from 'moment/locale/fi';

// components
import Layout from "./Layout";

// pages
// import Error from "../pages/error";
// import Login from "../pages/login";

// context
//import { useUserState } from "../context/UserContext";

export default function App() {
  
  moment.locale('fi');
  moment.updateLocale('fi', localization);
  // global
  //var { isAuthenticated } = useUserState();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/customer" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/customer" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    
    if (isLoading) {
      return null;
    }

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            loginWithRedirect()
          )
        }
      />
    );
  }
}
