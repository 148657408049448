import getApi from "./api";

export default function AttachDeviceToSite(token, siteId, device, tags, description, settings) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api.get('/attachDeviceToSite', {
      params: {
        site: {
          siteId, 
          device,  
          tags,
          description,
          settings
        }
      }
    })
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  })
}

