import { useState, useEffect, useCallback} from "react";
import getApi from "./api";

export default function useTagsForSite(token, siteId) {
  const [tags, setTags] = useState(false);
  const [error, setError] = useState(false);

  const update = useCallback(() => {
    if (token) {
      getTagsForSite(token, siteId)
        .then(res => {
          setTags(res);
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [token, siteId]);

  useEffect(() => {
    update();
  }, [update, token, siteId])

  return { tags, update, error };
}

export function getTagsForSite(token, siteId) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api
      .get("/tagsForSite", {
        params: {
          siteId,
        },
      })
      .then(res => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
