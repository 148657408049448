import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import CustomerIcon from '@material-ui/icons/PersonOutlined';

// context
import useToken from '../../services/useToken';
import { audience } from '../../services/db/api';
import useAlertStatesAndIncidents from '../../services/db/useAlertStatesAndIncidents';
import useTagsForSite from "../../services/db/useTagsForSite";
import { useFilter } from "../../components/Header/components/useFilter";
import { getSearchInfo } from "../../services/db/getSearchInfo";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import SiteTags from "./components/siteTags/SiteTags";
import AttachDevice from "./components/attachDevice/attachDevice";
import Timeline from "./components/timeline/timeline";
import ColorDescription from "./components/colorDescription/ColorDescription";


export default function ViewSite(props) {
  const classes = useStyles();

  const { siteId, incidentId, querySiteProps} = props;

  const [contactName, setContactName] = useState(props.contactName);
  const [location, setLocation] = useState(props.location);
  const [orgName, setOrgName] = useState(props.orgName);
  const [siteName, setSiteName] = useState(props.siteName);

  const { token } = useToken(audience);
  const [filter, setFilter] = useState({});
  const { tags } = useTagsForSite(token, siteId);
  const dateFilter = useFilter();
  const { incidents: alertStatesAndIncidents, update: updateAlertStateAndIncidents } = useAlertStatesAndIncidents(token, siteId, filter, dateFilter);
  const [openAttach, setOpenAttach] = useState(false);
  const [ deviceMap, setDeviceMap ] = useState();

  useEffect(() => {
    if (!querySiteProps) {
      return;
    }
    (async () => {
      let info = {};
      try {
        info = await getSearchInfo(token, siteId);
      } catch (err) {
        console.log("Error getting searchInfo:", err);
        // Todo handel error
        return;
      }
      setLocation(info.location);
      setOrgName(info.org_name);
      setSiteName(info.site_name);
      setContactName(info.fullname);
      
    })();
  }, [querySiteProps, token, siteId]);

  useEffect(() => {
    // Arrange incidents in prio order
    if (alertStatesAndIncidents) {
      const prioMap = {0:[],1:[],2:[],3:[],4:[],5:[],6:[],7:[],8:[],9:[]};
      Object.entries(alertStatesAndIncidents).forEach(([device_id, device]) => {
        if (!device.incidentTypes) {
          prioMap[9].push(device);
          return; 
        }
        let previous = null;
        Object.entries(device.incidentTypes).forEach(([key, incidentType]) => {
          let prio;
          switch (incidentType[0].severity) {
            case "Prio 1":
              prio = 0
              break;
            case "Prio 2":
              prio = 3
              break;
            case "In monitoring":
              prio = 6
              break;             
            default:
              break;
          }
          switch (incidentType[0].alert_state_now) {
            case "ACTIVE_ALERT":
              prio += 0
              break;
            case "RECENT_ALERT":
              prio += 1
              break;
            case "NORMAL":
              prio += 2
              break;             
            default:
              break;
          }
          if (previous !== null) {
            if (prio < previous) {
              previous = prio;
            }
            return;
          }
          previous = prio;
        })
        prioMap[previous].push(device); 
      });
      setDeviceMap(prioMap);
    }
  },[alertStatesAndIncidents])  

  const updateFilter = (newFilter) => {
    setFilter(newFilter);
  }

  const handleOpenAttach = () => {
    setOpenAttach(true);
  };

  const handleCloseAttach = (added) => {
    if (added) {
      updateAlertStateAndIncidents();
    }
    setOpenAttach(false);
  };

  return (
    <>
      <Grid container spacing={1} alignContent="center">
        <SiteTags token={token} tags={tags} onUpdateFilter={updateFilter} />
        <Grid item xs={7}><PageTitle title={siteName} /></Grid>
        <Grid item xs={5}><Fab variant="extended" color="primary" aria-label="add" onClick={handleOpenAttach}><AddIcon />Liitä laite</Fab></Grid>
        <Grid item xs={12} className={classes.siteDetails}>
          <Box className={classes.siteDetails}><LocationIcon size="small" /> {`\xa0\xa0${location ? location : ''}`}</Box>
        </Grid>
        <Grid item xs={12} className={classes.siteDetails}>
          <Box className={classes.siteDetails}><PeopleOutlineIcon size="small" /> {`\xa0\xa0${orgName}`}</Box>
        </Grid>
        <Grid item xs={12} className={classes.siteDetails}>
          <Box className={classes.siteDetails}><CustomerIcon size="small" />{`\xa0\xa0${contactName}`}</Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.siteDetails}>
            <ColorDescription level={'Prio 1'}/>
            <ColorDescription level={'Prio 2'}/>
            <ColorDescription level={'In monitoring'}/>
          </Box>
          { deviceMap ? Object.entries(deviceMap).map(([key, prio], index1) => prio.map((device, index2) => 
            <Timeline key={index1 + index2} token={token} siteId={siteId} device={device} siteTags={tags} incidentId={incidentId} update={updateAlertStateAndIncidents} />)) : null }   
        </Grid>
      </Grid>
      <AttachDevice token={token} open={openAttach} siteId={siteId} tags={tags} onClose={handleCloseAttach} />
    </>
  );
}
