import { useState, useEffect, useCallback  } from "react";
import getApi from "./api";
import { epochFormat } from "../../components/Header/components/useFilter";

export default function useCustomers(token, filter) {
  const [customers, setCustomers] = useState(false);
  const [error, setError] = useState(false);
  
  const update = useCallback(() => {
    if (token) {
      getCustomers(token, filter)
        .then((res) => {
          setCustomers(res);
        })
        .catch((err) => {
          setError(err);
        }) 
    }
  }, [token, filter]);
  
  useEffect(() => {
    update();
  }, [update, token, filter]);

  return { customers, update, error } 
}  

export function getCustomers(token, filter) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
  const { startDate, endDate } = epochFormat(filter);
    api.get('/customers', {
      params: {
        filter_start_date: startDate,
        filter_end_date: endDate
      }
    })
      .then((res) => {
        if(res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  })
}