import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomerIcon from "@material-ui/icons/PersonOutlined";

import useUsers from "../../../../../services/db/useUsers";

const filter = createFilterOptions();

export default function ContactPerson(props) {
  const { add, token } = props;

  const { users } = useUsers(token);

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    }
  }, [props.user]);

  useEffect(() => {
    add(user);
  }, [add, user]);

  return (
    <Autocomplete
      value={user}
      options={users ? users : []}
      onChange={(event, newCustomer) => {
        if (typeof newCustomer === "string") {
          setUser({
            fullname: newCustomer,
          });
        } else if (newCustomer && newCustomer.inputValue) {
          // Create a new customer
          setUser({
            fullname: newCustomer.inputValue,
          });
        } else {
          setUser(newCustomer);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            fullname: `LISÄÄ "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.fullname;
      }}
      renderOption={option => option.fullname}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="contact-person"
      disabled={props.user ? true : false}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Asiakkaan yhteyshenkilö"
          helperText="Tulee hakea vaihtoehtoja Insightlystä + omasta tietokannasta, tai luo uuden henkilön"
          margin="normal"
          required
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <CustomerIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    />
  );
}
