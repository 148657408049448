import React from "react";
import useIncident from "../../../../../../services/db/useIncident";
import AirFlow from "./components/topair/airFlow";
import DeadSensorCO2 from "./components/topair/deadSensorCO2";
import DeadSensorPower from "./components/topair/deadSensorPower";
import DeadSensorRPM from "./components/topair/deadSensorRPM";
import DeadSensorTemp from "./components/topair/deadSensorTemp";
import DeadSensorSuctionPressure from "./components/topair/deadSensorSuctionPressure";
import DeadSensorVOC from "./components/topair/deadSensorVOC";
import HighFanSpeed from "./components/topair/highFanSpeed";
import LowFanSpeed from "./components/topair/lowFanSpeed";
import DeadSensorRH from "./components/topair/deadSensorRH";
import HighRH from "./components/topair/highRH";
import HighVOC from "./components/topair/highVOC";
import CircularProgress from '@material-ui/core/CircularProgress';
import Pressure from "./components/sigfox/pressure";
import FewerSampleThanExpected from "./components/topair/fewerSamplesThanExpected";
import { useTheme } from '@material-ui/core/styles';

export default function Chart(props) {

  const { token, incident_id } = props;

  const theme = useTheme();
  const { incident, error } = useIncident(token, incident_id);

  // Colors
  const line1Color = theme.palette.chart.line1;
  const line2Color = theme.palette.chart.line2;
  const rangeColor = theme.palette.chart.range;

  // Line widths
  const rangeWidth = 1;
  const maxMinWidth = 0.5;

  // Line style
  // https://developers.google.com/chart/interactive/docs/lines
  const rangeStyle = [2, 2, 20, 2, 20, 2]

  const options = {
    height: 400,
    rangeSettings: { color: rangeColor.main, tooltip: true, lineWidth: rangeWidth, lineDashStyle: rangeStyle },
    line1Settings: { color: line1Color.main},
    line2Settings: { color: line2Color.main}, 
    maxMin1Settings: { color: line1Color.light, visibleInLegend: false, lineWidth: maxMinWidth},
    maxMin2Settings: { color: line2Color.light, visibleInLegend: false, lineWidth: maxMinWidth},
    format: { 
      hAxis: 'd.M.YYYY', // This is used by the chart x-axis labels
      textTime: 'ddd D.M.YYYY HH:mm', // This is used by the descriptive text below the Chart data
      tooltip: 'ddd d.M.YYYY HH:mm' // TODO: This should be used by the cart tooltip, but does not currently work.
    }
  }

  const getChart = () => {
   
    const { incident: info } = incident;

    switch (info.incidentType) {
      case 'sign_flow_undercut':
      case 'sign_flow_exceeded':
      case 'dead_flow_sensor':  
      case 'long_unreachable':  
      case 'short_unreachable':
      case 'flow_power_ratio_changed':
      case 'sensor_failure':
        return <AirFlow { ...incident } options={ options }/>
      case 'dead_CO2_sensor':
        return <DeadSensorCO2 { ...incident } options={ options }/>
      case 'dead_power_sensor':        
        return <DeadSensorPower { ...incident } options={ options }/>
      case 'dead_RH_sensor':
        return <DeadSensorRH { ...incident } options={ options }/>
      case 'dead_rpm_sensor':
        return <DeadSensorRPM { ...incident } options={ options }/>
      case 'dead_suction_pressure_sensor':
        return <DeadSensorSuctionPressure { ...incident } options={ options }/>
      case 'dead_VOC_sensor':
        return <DeadSensorVOC { ...incident } options={ options }/>
      case 'too_high_fanspeed':
        return <HighFanSpeed { ...incident } options={ options }/>
      case 'too_low_fanspeed':
        return <LowFanSpeed { ...incident } options={ options }/>
      case 'RH_high':
        return <HighRH { ...incident } options={ options }/>
      case 'VOC_high':
        return <HighVOC { ...incident } options={ options }/>
      case 'fewer_samples_than_expected':
        return <FewerSampleThanExpected { ...incident } options={ options }/>
      case 'flow_rpm_ratio_changed':
        // todo
        return null
      case 'dead_exchaust_sensor':
      case 'dead_outdoor_sensor':
        return <DeadSensorTemp { ...incident } options={ options }/>     
      // Sigfox
      case 'press_difference_undercut':
      case 'press_difference_exceeded':
        return <Pressure { ...incident } options={ options }/>
      default:
        console.log("Not supported incident type", info.incidentType);
        return null;
    }
  }

  return (
    <>
    { error ? <div>Kaaviokuvaa ei saatavilla.</div> : 
      incident ? getChart() : <div>Lataa kaaviokuvaa...<CircularProgress color="secondary" /></div>}
    </>
  )
}




