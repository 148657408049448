import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

// context
import useCustomers from '../../services/db/useCustomers';
import useToken from '../../services/useToken';
import { audience } from '../../services/db/api';
import { useFilter } from "../../components/Header/components/useFilter";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import CustomerCard from "./components/CustomerCard/CustomerCard"; 
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddCustomer from "./components/AddCustomer/AddCustomer";
import UpdateCustomer from "./components/UpdateCustomer/UpdateCustomer";
import DeleteError from "./components/DeleteError/DeleteError";

// styles
import useStyles from "./styles";


export default function Customers() {
  const classes = useStyles();
  const { token } = useToken(audience);

  const filter = useFilter();

  const { customers, update: updateCustomers } = useCustomers(token, filter ); 
  const [open, setOpen] = useState(false);
  const [updateCustomerData, setUpdateCustomerData] = useState({});
  const [deleteErrorOpen, setDeleteErrorOpen] = useState(false);
  
  let history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (site) => {
    setOpen(false);
    if (site && site.orgId && site.orgName) {
      history.push({ pathname: "/app/sites", state: { ...site } });
    }  
  };
   
  const handelUpdateOpen = (customer) => {
    setUpdateCustomerData(customer);
  }

  const handelUpdateClose = () => {
    setUpdateCustomerData({});
    updateCustomers();
  }

  const handelDeleteErrorOpen = () => {
    setUpdateCustomerData({});
    setDeleteErrorOpen(true);
  }

  return (   
  <>
  
    <Grid container spacing={1}>
        <Grid item xs={7}><PageTitle title="Asiakkaat" /></Grid>
        <Grid item xs={5}><Fab  variant="extended" color="primary" aria-label="add" onClick={handleClickOpen}><AddIcon />Lisää asiakas</Fab></Grid>
    </Grid>    
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignContent="center" alignItems="center" spacing={1} wrap="nowrap">
                <Grid item xs={5} zeroMinWidth />
                <Grid item className={classes.header} xs={2} zeroMinWidth>Vikatilanne päällä</Grid>
                <Grid item className={classes.header} xs={2} zeroMinWidth>Vikatilanne ohi</Grid>
                <Grid item className={classes.header} xs={3} zeroMinWidth>Seurattavat laitteet</Grid>
        </Grid>
      </Grid>
   
      {customers ? customers.map((customer, index) => {
        return <Grid item xs={12} key={index}>
          <CustomerCard customer={customer} onUpdate={handelUpdateOpen}/>
        </Grid>
      }) : null}
    </Grid>
    <AddCustomer token={token} open={open} onClose={handleClose} />
    <UpdateCustomer token={token} open={Object.keys(updateCustomerData).length === 0 ? false : true} onClose={handelUpdateClose} onError={handelDeleteErrorOpen} customer={updateCustomerData}/>
    <DeleteError title="Asiakkaan poisto epäonnistui" bodyText="Vähintään yksi kohde on edelleen liitetty siihen. Muuta näiden kohteiden asiakas ensin, jonka jälkeen voit poistaa tätä asiakasta." open={deleteErrorOpen} onClose={() => setDeleteErrorOpen(false)} />
  </>
  );
}