import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

// styles
import useStyles from "./styles";

export default function FilterDialog(props) {

  const classes = useStyles();

  const { onClose, open, columns} = props;
  const [checked, setChecked] = useState([]);
  const [ currentColumns, setCurrentColumns ] = useState([]);
  const [ refresh, setRefresh ] = useState(); 

  useEffect(() => {
    if (columns) {
      const newChecked = [];
      const copyOfColumns = [];
      columns.forEach(element => {
        // Need to make a new array so the current table dos not update constantly
        const newElement = {'title': element.title, 'field': element.field, 'hidden': element.hidden};
        if (!newElement.hidden) {
          newChecked.push(newElement);
        }
        copyOfColumns.push(newElement);
      });
      setChecked(newChecked);
      setCurrentColumns(copyOfColumns);
    }
  }, [columns, refresh]);

  const handleToggle = (element) => () => {
    const currentIndex = checked.indexOf(element);
    const newChecked = [...checked];
    element.hidden = !element.hidden;
    if (currentIndex === -1) {
      newChecked.push(element);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handelUpdate = () => {
    handelOnClose(currentColumns);
  }

  const handelOnClose = (val) => {
    if (!val) {
      setRefresh(!refresh);
    }
    onClose(val);
  }

  return (
      <Dialog open={open} onClose={() => handelOnClose(false)}  maxWidth = {'sm'} fullWidth={true} aria-labelledby="form-dialog-title">
        <DialogContent>
        <List dense={true}>
            { currentColumns.map((element, index) => 
              <ListItem className={classes.list} key={index} onClick={handleToggle(element)}>
                <ListItemIcon>
                  <Checkbox 
                    color="primary" 
                    checked={checked.indexOf(element) !== -1}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={element.title}
                />
              </ListItem>
            )}
          </List>
          {/* (deviceInfo && deviceInfo[2]) ? drawSettings() : null */}
        </DialogContent> 
        <DialogActions>
          <Button onClick={() => handelOnClose(false)} color="primary">   
            Peruuta
          </Button>
          <Button variant="contained" onClick={handelUpdate} color="primary">
            Päivitä
          </Button>
        </DialogActions>
      </Dialog>
  );
}

    