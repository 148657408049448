import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 

import { DateRangePicker } from 'react-date-range';
import fi from 'date-fns/locale/fi';
import { customStaticRanges, defaultSelection, getSelected} from './customDateRange';
import moment from 'moment';

// styles
import useStyles from "./styles";
import { useTheme } from '@material-ui/core/styles';

// components
import { Typography } from "../../Wrappers/Wrappers";
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { useFilter, setFilter } from "./useFilter";

export default function FilterMenu(props) {
  const classes = useStyles();
  moment.locale('fi');
  const theme = useTheme();

  const filter = useFilter();
  const [ selectedDate, setSelectedDate ] = useState(defaultSelection(filter))

  const handleSelect = (data) =>{
    // There must be a beater way to catch if the selection is made from the calender
    if (moment(data.selection.startDate).format('HH:mm:ss') === '00:00:00') {
      data.selection.tag = 'selection';
    }
    setSelectedDate(data.selection);
  }

  return (
    <Menu
      {...props}
    >
      <div className={classes.filterMenuDiv}>
        <Typography variant="h6" weight="medium">Sivustolaajuiset häiriösuodatukset</Typography>
        <DateRangePicker
          locale={fi}
          ranges={[selectedDate]}
          staticRanges={customStaticRanges}
          inputRanges={[]}
          onChange={handleSelect}
          rangeColors={[theme.palette.primary.main]}
          color={theme.palette.primary.main}
        />
        <MenuItem className={ classes.filterMenuItem }
          onClick={() => {
            const filter = getSelected(selectedDate);
            if (filter) {
              setFilter(filter);
            } 
            props.onClose();
          }}
          >
          <Button color="primary" variant="contained">Käytä</Button>
        </MenuItem>  
      </div>
  </Menu>
  );
}



