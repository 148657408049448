import { useState, useEffect, useCallback } from "react";
import getApi from "./api";

export default function useCommentsForDevice(token, deviceId) {
  const [comments, setComments] = useState(false);
  const [error, setError] = useState(false);

  const update = useCallback(() => {
    if (token) {
      getDevice(token, deviceId)
        .then(res => {
          setComments(res);
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [token, deviceId]);

  useEffect(() => {
    update();
  }, [update, token]);

  return { comments, update, error };
}

export function getDevice(token, deviceId) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api
      .get("/commentsForDevice", {
        params: {
          deviceId,
        },
      })
      .then(res => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
