import { useState, useEffect } from "react";
import getApi from "./api";

export default function useIncident(token, incidentId) {
  const [incident, setIncident] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (token) {
      getIncident(token, incidentId)
        .then(res => {
          setIncident(res);
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [token, incidentId]);

  return { incident, error };
}

export function getIncident(token, incidentId) {
  const api = getApi(token);
  return new Promise((resolve, reject) => {
    api
      .get("/incident", {
        params: {
          incidentId,
        },
      })
      .then(res => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
