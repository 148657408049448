import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    //maxWidth: 345,
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  businesscard: {
    backgroundColor: 'transparent'
  },
  siteDetails: {
    display: 'flex',
    spacing: theme.spacing(1),
  },
  card: {
    borderRadius: 15,
    //backgroundColor: theme.palette.background.light,
    //color: theme.palette.primary.contrastText,
    boxShadow: 3,
    padding: `0 px`,
    marginBottom: 10,
  }

  
  
}));
