import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

// context
import useSites from '../../services/db/useSites';
import useToken from '../../services/useToken';
import { audience } from '../../services/db/api';
import { useFilter } from "../../components/Header/components/useFilter";


// components
import PageTitle from "../../components/PageTitle/PageTitle";
import SiteCard from "./components/SiteCard/SiteCard"; 
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddSite from "./components/addSite/AddSite";
import UpdateSite from "./components/updateSite/UpdateSite";

// styles
import useStyles from "./styles";

// This page can either list all sites, or filter them 
// based on a customer org id (provided in props)
export default function Sites(props) {
  
  const classes = useStyles();

  const { orgId, orgName } = props;
  const { token } = useToken(audience);
  const filter = useFilter();
  const { sites, update: updateSites } = useSites(token, orgId, filter);

  const [open, setOpen] = useState(false);
  const [updateSiteData, setUpdateSiteData] = useState({});
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (site) => {
    setOpen(false); 
  };

  const handelUpdateOpen = (data) => {
    setUpdateSiteData(data);
  }

  const handelUpdateClose = () => {
    setUpdateSiteData({});
    updateSites();
  }

  useEffect(() => {
    if (sites && sites.length === 0) {
      setOpen(true);
    }
  }, [sites])

  return (   
  <>
    <Grid container spacing={1}>
    { orgId && orgName ? <>
        <Grid item xs={7}><PageTitle title={orgName} /></Grid>
        </>:
        <Grid item xs={7}><PageTitle title="Kohteet" /></Grid>
    }
        <Grid item xs={5}><Fab  variant="extended" color="primary" aria-label="add" onClick={handleClickOpen}><AddIcon />{`Lisää kohde`}</Fab></Grid>
    </Grid>    
    <Grid container spacing={1}>
   
    <Grid item xs={12}>
      <Grid container alignContent="center" alignItems="center" spacing={1} wrap="nowrap">
              <Grid item xs={5} zeroMinWidth />
              <Grid item className={classes.header} xs={2} zeroMinWidth>Vikatilanne päällä</Grid>
              <Grid item className={classes.header} xs={2} zeroMinWidth>Vikatilanne ohi</Grid>
              <Grid item className={classes.header} xs={3} zeroMinWidth>Seurattavat laitteet</Grid>
      </Grid>
    </Grid>
      {sites ? sites.map((site, index) => {
        return <Grid item xs={12} key={index}>
          <SiteCard site={site} onUpdate={handelUpdateOpen}/>
        </Grid>
      }) : null}
    </Grid>
    <AddSite token={token} open={open} onClose={handleClose} org={ (orgId && orgName) ? {id: orgId, name: orgName} : null} />
    {Object.keys(updateSiteData).length === 0 ? null : <UpdateSite token={token} open={Object.keys(updateSiteData).length === 0 ? false : true} onClose={handelUpdateClose} site={updateSiteData} />}
  </>
  );
}