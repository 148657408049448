import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessIcon from '@material-ui/icons/Business'
import LocationIcon from "@material-ui/icons/LocationOnOutlined"
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Customer from './components/customer';
import ContactPerson from './components/contactPerson';
import Tags from './components/tags';
import Typography from "@material-ui/core/Typography";

import addSite from '../../../../services/db/addSite';

// styles
import useStyles from "./styles";
import useTagTypes from '../../../../services/db/useTagTypes';


export default function AddSite(props) {

  const classes = useStyles();

  const { onClose, open, token, org } = props;
  
  const { tags } = useTagTypes(token);
//const { customers } = useCustomers(token);

  const [ name, setName ] = useState();
  const [ location, setLocation ] = useState();
  const [ customer, setCustomer ] = useState();
  const [ user, setUser ] = useState();
  const [ siteTags, setSiteTags ] = useState(); 

  const addSiteTags = (id, tags) => {
    const obj = {...siteTags};
    obj[id] = tags;
    setSiteTags(obj);
  }

  const submitSite = () => { 
    addSite(token, name, location, customer, user, siteTags)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        // Todo add error dialog
        console.log("Error", err);
      })
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={submitSite}>
      <DialogTitle id="form-dialog-title">Kohteen määrittely</DialogTitle>       
      <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField 
                label="Kohteen nimi" 
                helperText="Esim AsOy:n tai KOy:n nimi"
                fullWidth
                margin="normal"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setName(event.target.value)}    
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Customer token={token} add={setCustomer} org={org}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ContactPerson add={setUser} token={token} user={null}/>
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                label="Kohteen sijainti"
                helperText="Katuosoite + katunumero, kaupunki"
                required
                onChange={(event) => setLocation(event.target.value)}    
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationIcon />
                    </InputAdornment>
                  ),
              }} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid> 
            <Grid item xs={12} sm={10}>  
              <Typography  className={classes.subheader} >Määrittele tälle kohteelle tageja, jolla haluat tunnistaa mittaustiedot ja erotella niitä toisistaan. 
                Jos kohteessa on esim. vain yksi rakennus, niin rakennus-tagiä ei tarvitse/kannata määritellä. Tagit ovat vapaaehtoiset.
              </Typography>      
            </Grid>
            { tags ? tags.map((type, index) => {
              return (
                <Grid key={index} item xs={12} sm={10}>  
                  <Tags type={type} add={addSiteTags}/>  
                </Grid>
              )
            }) : null}
          </Grid>
        </DialogContent>
      
        <DialogActions>
          <Button onClick={onClose} color="primary">   
            Peruuta
          </Button>
          <Button variant="contained" type="submit" color="primary">
            Tallenna
          </Button>
        </DialogActions>
        </form>
      </Dialog>
  );
}