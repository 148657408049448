import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SenserComponent from "./components/senser";
import TopairComponent from "./components/topair";

import Devices from "./components/devices";
import { deviceTypes } from "../../../../Constants";

const {
  TopAir,
  SF_MultiGuard_R2,
  SF_PressGuard_R4_1,
  SF_Ranger_1_3,
  SF_TempGuard_R3,
  Senser
} = deviceTypes;

export default function AddDevice(props) {
  const { onClose, open, token } = props;

  const [deviceType, setDeviceType] = useState();
  const [submit, setSubmit] = useState(false);

  const submitSite = () => {
    setSubmit(true);
  };

  const closeAddDialog = () => {
   
   // setSettings(undefined);
   // setDescription(undefined);
   // setDevice(undefined);
    setSubmit(false);
    onClose();
  };

  const drawSettings = () => {
    switch (parseInt(deviceType.id)) {
      case Senser:
        return <SenserComponent submit={submit} token={token}/>
      case TopAir:
        return <TopairComponent submit={submit} token={token}/>;
      case SF_MultiGuard_R2:
        return null;
      case SF_PressGuard_R4_1:
        return null;
      case SF_Ranger_1_3:
        return null;
      case SF_TempGuard_R3:
        return null;
      default:
        return null;
    }
  };

  const handelSelect = (type) => {
    setSubmit(false);
    setDeviceType(type);
  }

  return (
    <Dialog
      open={open}
      onClose={closeAddDialog}
      maxWidth={"sm"}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={submitSite}>
        <DialogTitle id="form-dialog-title">Lisää laite</DialogTitle>
        <DialogContent>
          <Devices token={token} select={handelSelect} />
          {deviceType ? drawSettings() : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeAddDialog(false)} color="primary">
            Peruuta
          </Button>
          <Button variant="contained" type="submit" color="primary">
            Lisää
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
